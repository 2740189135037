import ApiService from './apiService'; 
import { IDocListResponse } from '../models/IDocListResponse';

class DocListApiService {
    private apiService!: ApiService;

    constructor() {
        this.apiService = new ApiService(process.env.REACT_APP_INTEGRATION_API ?? "");
    }

    public async pollDocList(transactionId: string, token: string) {
        const resp = await this.apiService.get<IDocListResponse>(`/doclist/${transactionId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }) 
        return resp.data;
    }
}

export default DocListApiService;