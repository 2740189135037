import React, { useEffect, useState } from "react";
import { Card, CardContent, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { IExpression, IDeliveryType } from "../../../models/configuration/plugin/IConfiguration";
import ExpressionEditor from "../../shared/config/ExpressionEditor";
import GenericDialog from "../../shared/GenericDialog";
import { ExpressionValidationResult, validateExpression } from "../../../utils/expressionHelper";

interface DeliveryTypeDetailsProps {
    selectedDeliveryType: IDeliveryType | null,
    handleOnEnabledChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleOnOrderChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleOnCaptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleOnToolTipTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleOnAutoSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const autoSelectExpressionState: IExpression = {
    ExpressionName: "Auto Select When",
    ExpressionString: "",
    ExpressionMessage: "",
    Enabled: false
}

const DeliveryTypeDetails: React.FC<DeliveryTypeDetailsProps> =
    ({
        selectedDeliveryType: selectedType,
        handleOnEnabledChange,
        handleOnOrderChange,
        handleOnCaptionChange,
        handleOnToolTipTextChange,
        handleOnAutoSelectChange
    }) => {

        const [order, setOrder] = useState<number>(0);
        const [autoSelect, setAutoSelect] = useState<boolean>(false);
        const [autoSelectExpressionOpen, setAutoSelectExpressionOpen] = useState(false);
        const [autoSelectExpression, setAutoSelectExpression] = useState<IExpression>(autoSelectExpressionState);

        useEffect(() => {
            if (selectedType) {
                setOrder(selectedType.Order ?? 0);
                setAutoSelectExpression((prevState) => ({
                    ...prevState,
                    ExpressionString: selectedType.AutoSelectWhen ?? ""
                }));
                handleAutoSelectCheckBox(selectedType.AutoSelectWhen || "");
            }
        }, [selectedType]);

        const handleAutoSelectOpen = (_source: string) => { setAutoSelectExpressionOpen(true); }
        const handleAutoSelectClose = () => setAutoSelectExpressionOpen(false);

        // Validate IExpression
        const handleValidateExpression = (expression: IExpression): ExpressionValidationResult | null => {
            if (expression && expression.ExpressionString?.length > 1) {
                const result = validateExpression(expression.ExpressionString);
                if (result.isValid) {
                    return {
                        isValid: true,
                        errors: []
                    }
                }
            }
            return {
                isValid: false,
                errors: ['Expression string cannot be empty']
            };
        };

        // Updates the Check Box and the Auto Select Expression
        const handleAutoSelectCheckBox = (expressionStr: string) => {
            if (expressionStr === undefined || expressionStr.length < 1) {
                setAutoSelect(false);
                return;
            }

            const result = validateExpression(expressionStr);
            if (result.isValid) {
                setAutoSelect(true);
            }
        }

        const updateExpressions = (expression: IExpression, _mode: string): string | null => {
            if (expression) {
                handleOnAutoSelectChange({ target: { value: expression.ExpressionString } } as React.ChangeEvent<HTMLInputElement>);
                setAutoSelectExpression(expression); // This is for the Dialog 
                setAutoSelectExpressionOpen(false);
            }
            return null;
        }

        return (
            <>
                <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent>
                        <div className="deliveryTypeDetailsContainer">
                            <div className="deliveryTypeDetails-Row1">
                                <div className="deliveryTypeDetails-Row1-Left-Section">
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleOnEnabledChange} checked={selectedType?.Enabled ?? false} />}
                                        label="Enabled"
                                    />

                                    <Typography variant="subtitle1" sx={{ marginRight: "5px" }} >Order:</Typography>
                                    <TextField
                                        sx={{ width: "5rem" }}
                                        type="number"
                                        size="small"
                                        value={selectedType?.Order?.toString() || order}
                                        onChange={(e) => {
                                            const value = Math.min(100, Math.max(0, Number(e.target.value)));
                                            setOrder(value);
                                            handleOnOrderChange({ target: { value: value.toString() } } as React.ChangeEvent<HTMLInputElement>);
                                        }}
                                        inputProps={{ min: 0, max: 90, step: 10 }}
                                    />
                                </div>
                                <div className="deliveryTypeDetails-Row1-Right-Section">
                                    <Typography variant="subtitle1" sx={{ margin: "5px" }}>Caption:</Typography>

                                    <TextField
                                        onChange={handleOnCaptionChange}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={selectedType?.Caption ?? ""}
                                        sx={{ flex: 1, width: "350px" }}
                                    />
                                </div>
                            </div>
                            <div className="deliveryTypeDetails-Row2">
                                <div className="deliveryTypeDetails-Row2-Left-Section">
                                    <FormControlLabel
                                        control={<Checkbox
                                            onClick={() => handleAutoSelectOpen('edit')}
                                            checked={autoSelect ?? false} />}
                                        label=""
                                    />

                                    <Typography
                                        variant="subtitle1"
                                        onClick={() => handleAutoSelectOpen('edit')}
                                        sx={{
                                            "&:hover": {
                                                textDecoration: "underline",
                                                cursor: "pointer"
                                            }, color: "blue"
                                        }}>
                                        Auto Select When
                                    </Typography>
                                </div>

                                <div className="deliveryTypeDetails-Row2-Right-Section">
                                    <Typography variant="subtitle1" sx={{ margin: "5px" }}>Tooltip:</Typography>
                                    <TextField
                                        onChange={handleOnToolTipTextChange}
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={selectedType?.ToolTipText ?? ""}
                                        sx={{ flex: 1, width: "350px" }}
                                    />
                                </div>

                            </div>

                            <GenericDialog
                                open={autoSelectExpressionOpen}
                                onClose={handleAutoSelectClose}
                                title="Expression Editor"
                            >
                                <ExpressionEditor
                                    expression={autoSelectExpression}
                                    onValidate={handleValidateExpression}
                                    onSave={updateExpressions}
                                    editorMode={'mode'}
                                />

                            </GenericDialog>
                        </div>
                    </CardContent>
                </Card>
            </>

        );
    }

export default DeliveryTypeDetails;