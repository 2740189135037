import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import {
  IConfiguration,
  IPushbackConfiguration,
  IMessageType
} from "../../models/configuration/pushback/IConfiguration";
import EncompassService from "../../services/encompassService";
import { GetFieldName } from "../../constants/PushbackConstants";

// This action creator gets the server config values from an api call or state
const saveConfigPushbackData = (
  configId: string,
  updatedPushbackData: IConfiguration,
  token: string | null
) => {
  return async (dispatch: Dispatch<Action>, getState: () => any) => {
    dispatch({ type: ActionType.SAVE_CONFIG_PUSHBACK });

    let status: IMessageType = { Type: "", Message: [] };
    try {

      const { data: pushbackConfiguration } = getState().configPushbackDefaults;
      if (!pushbackConfiguration) {
        throw new Error(" No PushbackConfiguration available in state to save");
      }

      if (!updatedPushbackData) {
        throw new Error("No Pushback Defaults to save");
      }
      
      //If field value is null or empty consider as default success field list and map it to equivalent UI field name
      const defaultFieldKeys: any = Object.entries(
        updatedPushbackData as Object
      )
        .filter(
          ([key, value]) =>
            (value === null || value === "") && GetFieldName(key) !== undefined && value !== 0
        )
        .map(([key, value]) => {
          return GetFieldName(key);
        });
        
      const updatedConfig = {
        //submit default values if form values are null
        ...pushbackConfiguration.Configuration,
        EncompassInstanceId: EncompassService.getEncompassInstanceId(),
        ReceivedDatePopulation: updatedPushbackData?.ReceivedDatePopulation
          ? updatedPushbackData.ReceivedDatePopulation
          : pushbackConfiguration?.Configuration?.ReceivedDatePopulation,
        UnnamedDocumentTitle: updatedPushbackData?.UnnamedDocumentTitle
          ? updatedPushbackData.UnnamedDocumentTitle
          : pushbackConfiguration?.Configuration?.UnnamedDocumentTitle,
        MaxMessageRetryCount: updatedPushbackData?.MaxMessageRetryCount ===0 || updatedPushbackData?.MaxMessageRetryCount 
          ? updatedPushbackData.MaxMessageRetryCount
          : pushbackConfiguration?.Configuration?.MaxMessageRetryCount,
        MessageRetryVisibilityTimeoutSeconds:
          updatedPushbackData?.MessageRetryVisibilityTimeoutSeconds
            ? updatedPushbackData.MessageRetryVisibilityTimeoutSeconds
            : pushbackConfiguration?.Configuration
                ?.MessageRetryVisibilityTimeoutSeconds,
        LoanLockedVisibilityTimeoutSeconds:
          updatedPushbackData?.LoanLockedVisibilityTimeoutSeconds
            ? updatedPushbackData.LoanLockedVisibilityTimeoutSeconds
            : pushbackConfiguration?.Configuration
                ?.LoanLockedVisibilityTimeoutSeconds,
        MaxConcurrency: updatedPushbackData?.MaxConcurrency
          ? updatedPushbackData.MaxConcurrency
          : pushbackConfiguration?.Configuration?.MaxConcurrency,
      };

      const requestBody = {
        ConfigurationId: configId,
        EncompassInstanceId: EncompassService.getEncompassInstanceId(),
        Configuration: updatedConfig,
      };

      await new PluginService().saveConfig<IPushbackConfiguration>(
        token,
        "pushback-processor",
        configId,
        requestBody
      );

      const msg = defaultFieldKeys.length
        ? "Default Data is Saved For Following Fields "
        : "Successfully Saved Pushback Config Changes";
      status.Type = "success";
      status.Message.push(msg, ...defaultFieldKeys);
      
      dispatch({
        type: ActionType.SAVE_CONFIG_PUSHBACK_SUCCESS,
        payload: { Configuration: updatedConfig },
      });

      return Promise.resolve(status);
    } catch (error: any) {
      console.error("Save Pushback Defaults Data");
      const payloadErrorMessage = `Failed to save Pushback Defaults Data: (${error.message})`;
      status.Type = "error";
      status.Message = [payloadErrorMessage];
      dispatch({
        type: ActionType.SAVE_CONFIG_PUSHBACK_FAILURE,
        payload: payloadErrorMessage,
      });
      return Promise.reject(status);
    }
  };
};

export default saveConfigPushbackData;