import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import PluginService from '../../services/pluginService';
import { IBundleConfiguration, IDocumentBundle } from '../../models/configuration/bundles/IBundles';

interface BundlingState {
  config: IBundleConfiguration | null;
  loading: boolean;
  error: string | null;
}

const initialState: BundlingState = {
  config: null,
  loading: false,
  error: null,
};

export const importBundlingConfig = createAsyncThunk(
  'importBundling/importBundlingConfig',
  async ({ configurationId, config }: { configurationId: string; config: Array<IDocumentBundle> }, { getState }) => {
    const state = getState() as RootState;
    const token = state.appSlice.accessToken;
    const pluginService = new PluginService();
    const requestBody = {
        "ConfigurationId": configurationId,
        "Configuration": {
            "Bundling": config
        }
    }
    return await pluginService.saveConfig<IBundleConfiguration>(token, "document-bundling", configurationId, requestBody);
  }
);

const importBundlingSlice = createSlice({
  name: 'bundling',
  initialState,
  reducers: {
    clearBundlingConfig(state) {
      state.config = null;
    },
  },
  extraReducers: (builder) => {
    builder      
      .addCase(importBundlingConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(importBundlingConfig.fulfilled, (state, action: PayloadAction<IBundleConfiguration>) => {
        state.loading = false;
        state.config = action.payload;
      })
      .addCase(importBundlingConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to save bundling config';
      });
  },
});

export const { clearBundlingConfig } = importBundlingSlice.actions;
export default importBundlingSlice.reducer;
