import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import PluginService from '../../services/pluginService';
import { IEventBundle, IEventBundleConfiguration } from '../../models/configuration/bundles/IBundles';

interface EventBundlingState {
  config: IEventBundleConfiguration | null;
  loading: boolean;
  error: string | null;
}

const initialState: EventBundlingState = {
  config: null,
  loading: false,
  error: null,
};

export const importEventBundlingConfig = createAsyncThunk(
  'importBundling/importEventBundlingConfig',
  async ({ configurationId, config }: { configurationId: string; config: Array<IEventBundle> }, { getState }) => {
    const state = getState() as RootState;
    const token = state.appSlice.accessToken;
    const pluginService = new PluginService();
    const requestBody = {
        "ConfigurationId": configurationId,
        "Configuration": {
            "Bundling": config
        }
    }
    return await pluginService.saveConfig<IEventBundleConfiguration>(token, "event-bundling", configurationId, requestBody);
  }
);

const importEventBundlingSlice = createSlice({
  name: 'bundling',
  initialState,
  reducers: {
    clearBundlingConfig(state) {
      state.config = null;
    },
  },
  extraReducers: (builder) => {
    builder      
      .addCase(importEventBundlingConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(importEventBundlingConfig.fulfilled, (state, action: PayloadAction<IEventBundleConfiguration>) => {
        state.loading = false;
        state.config = action.payload;
      })
      .addCase(importEventBundlingConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to save bundling config';
      });
  },
});

export const { clearBundlingConfig } = importEventBundlingSlice.actions;
export default importEventBundlingSlice.reducer;
