import { PRESIGN_API_OBJECTS } from "../utils/constants";
import PresignUrlApiService from "./docGenApiService";
import { store } from "../state/store"
import { setLoading } from "../state/appSlice";
import JSZip from "jszip";

class PreviewDocsService {
    public async downloadMetadata(transactionId: string | null, authToken: string, requests: any) {
        store.dispatch(setLoading(true));
        const service = new PresignUrlApiService();
        const response = await service.getPresignResponse(transactionId ?? requests[0].RequestId, authToken, [PRESIGN_API_OBJECTS.METADATA]);
        if (response.result.signedUrls && response.result.signedUrls['metadata.zip']) {
            //download metadata.zip
            await fetch(response.result.signedUrls['metadata.zip'], {
                method: 'GET',
                headers: {
                    'x-bearer-auth': authToken
                }
            })
                .then(async (response) => {
                    if (response.ok) {
                        const blob = await response.blob()
                        if (blob) {
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'metadata.zip');
                            document.body.appendChild(link);
                            link.click();
                            link.parentNode?.removeChild(link);
                        }
                    }
                    else {
                        console.log("Error fetching metadata.zip", response.status)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    store.dispatch(setLoading(false));
                })
        }
    }

    public async previewDocument(transactionId: string | null, authToken: string, requests: any) {
        store.dispatch(setLoading(true));

        const service = new PresignUrlApiService();
        const response = await service.getPresignResponse(transactionId ?? requests[0].RequestId, authToken, [PRESIGN_API_OBJECTS.METADATA]);
        let b64Url: string = "";
        if (response.result.signedUrls && response.result.signedUrls['metadata.zip']) {
            //download metadata.zip
            await fetch(response.result.signedUrls['metadata.zip'], {
                method: 'GET',
                headers: {
                    'x-bearer-auth': authToken
                }
            })
                .then(async (response) => {
                    if (response.ok) {
                        const blob = await response.blob()
                        if (blob) {
                            const unzip = await JSZip.loadAsync(blob);
                            if (unzip.files["docgen_response.xml"]) {
                                const xmlText = await unzip.files["docgen_response.xml"].async('string');
                                const parser = new DOMParser();
                                const doc = parser.parseFromString(xmlText, "application/xml");
                                const emortPackage = doc.getElementsByTagName("EMORTGAGE_PACKAGE"); 
                                //Download pdf
                                //chrome is not playing nice with displaying in new window
                                if (emortPackage[0]?.getElementsByTagName("EMBEDDED_FILE")[0]?.getElementsByTagName("DOCUMENT")[0]?.innerHTML) {
                                    const embeddedFile = emortPackage[0].getElementsByTagName("EMBEDDED_FILE")[0];
                                    const embededDocument = embeddedFile.getElementsByTagName("DOCUMENT")[0];

                                    const url = `data:application/pdf;base64,${embededDocument.innerHTML}`;
                                    console.log("PDFURL",url);
                                    const link = document.createElement('a');
                                    link.href = url;
                                    //link.setAttribute('download', 'Package.pdf');
                                    document.body.appendChild(link);
                                    link.click();
                                    link.parentNode?.removeChild(link);   
                                    b64Url = url;  
                                }

                                //Else if no document, open conformx window
                                else if (emortPackage[0]?.getElementsByTagName("KEY")) {
                                    const keyTag = emortPackage[0].getElementsByTagName("KEY");
                                    if (keyTag[0].getAttribute("_Name") === "URL") {
                                        window.open(keyTag[0].getAttribute("_Value") ?? "", "_blank");
                                    }
                                    return null;
                                }
                            }
                        }
                    }
                    else {
                        console.log("Error fetching metadata.zip", response.status)
                    } 
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    store.dispatch(setLoading(false));
                })
        }
        return b64Url;
    }
}

export default PreviewDocsService;