import React, { useEffect, useState } from 'react';
import {Card, CardContent, Tab, Tabs} from '@mui/material';
import CustomTabPanel from '../shared/CustomTabPanel';
import DTDataGrid from "../../components/shared/config/DTDataGrid";
import {IPackage} from "../../models/configuration/plugin/IConfiguration";
import {evaluationResultsColumns, validDeliveryMethodsColumns} from "./EvaluationResultsData";
import {SpecialDeliveryTypes, FullfillmentShippingOptions, SpecialClosingTypes} from "../../enums/SpecialDeliveryTypes";

interface EvaluationResultsProps {
    packageList: IPackage[] | undefined;
}

const EvaluationResults : React.FC<EvaluationResultsProps> = ({packageList}) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [evaluationResults, setEvaluationResults] = useState<Array<IPackage>>([]);//TODO: Pending Tab0 due to expression dependency
    const [validDeliveryMethods, setValidDeliveryMethods] = useState<Array<IPackage>>([]);

    useEffect(() => {
        if (packageList != null) {
            const packageUpdated = getUpdatedPackageId(packageList);
            // Creating a shallow copy to avoid mutation
            let updatedPackageList : IPackage[] = [...packageUpdated]
            packageList = updatedPackageList.map((pkg : IPackage) => {
                return {...pkg, ValidDeliveryTypes : AddSpecialDeliveryTypes(pkg.ValidDeliveryTypes)};
            })
            setValidDeliveryMethods(packageList);
        }
    }, [packageList]);

   
    const getUpdatedPackageId = (pkgList : IPackage[]) : IPackage[] => {
        return pkgList.map((pkg : IPackage) => 
            {
                return {
                    ...pkg,
                    Id : (pkg.ParentPackageId == null || pkg.ParentPackageId === undefined || pkg.ParentPackageId === "") ? pkg.Id.trim()  :
                        pkg.AlternatePackageId?.trim() + " [" + pkg.Id.trim() + "]" }
            })
        }

    const AddSpecialDeliveryTypes = (deliveryTypes : string[]) => {
            let updatedDeliveryTypes = [...deliveryTypes] //avoid mutating the array
            
            //Adding custom delivery
            updatedDeliveryTypes.push(SpecialDeliveryTypes.PrintInPerson);

            //Adding special delivery types for FullFillmentShippingOptions
            if(updatedDeliveryTypes.includes("Mail")){
                for(let deliveryType in FullfillmentShippingOptions)
                {
                    updatedDeliveryTypes.push(`Mail-${deliveryType}`)
                }
            }

            //Adding special delivery types for SpecialClosingTypes
            if(updatedDeliveryTypes.includes("eClose")){
                for(let deliveryType in SpecialClosingTypes)
                {
                    updatedDeliveryTypes.push(`eClose-${deliveryType}`)
                }
            }

            updatedDeliveryTypes.some(pkg => pkg.startsWith("eSign")) ?? updatedDeliveryTypes.push(SpecialDeliveryTypes.ESignSecondaryId);
            updatedDeliveryTypes.some(pkg => pkg.startsWith("eDisclose")) ?? updatedDeliveryTypes.push(SpecialDeliveryTypes.EDsicloseSecondaryId);
            updatedDeliveryTypes.some(pkg => pkg.startsWith("eClose")) ?? updatedDeliveryTypes.push(SpecialDeliveryTypes.ECloseSecondaryId);
       
        return updatedDeliveryTypes;
    }

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    function tabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

	return (
        <div>
        <Card
            sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: 2
            }}
        >
            <CardContent>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Evaluations" {...tabProps(0)} />
                    <Tab label="Valid Delivery Methods" {...tabProps(1)} />
                </Tabs>
                <CustomTabPanel value={tabValue} index={0}>
                    <DTDataGrid
                        data={evaluationResults}
                        columns={evaluationResultsColumns()}
                        tableName="Test"
                        containerSx={{ maxHeight: "60vh" }}
                    />
                </CustomTabPanel>

                <CustomTabPanel value={tabValue} index={1}>
                    <DTDataGrid
                        data={validDeliveryMethods}
                        columns={validDeliveryMethodsColumns()}
                        tableName="ValidDeliveryMethods"
                        containerSx={{ maxHeight: "60vh"}}
                    />
                </CustomTabPanel>
            </CardContent>
        </Card>
    </div>
	);
};

export default EvaluationResults;