import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ConfigLoad from "./ConfigLoad";
import { useConfigurationData, ConfigurationDataType } from "../../../hooks/useConfigurationData";
import EncompassService from "../../../services/encompassService";
import { useActions } from "../../../hooks/useActions";
import { RootState } from "../../../state/store";
import { useSelector } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  BundlingOption,
  IDocumentBundling,
  IDocumentHandlingPackageConfiguration,
  IEventsBundling,
  IMergerPackageConfiguration,
  SignedOrUnsigned,
} from "../../../models/configuration/importExport/IDocumentBundling";
import {
  IDocumentBundle,
  IEventBundle,
} from "../../../models/configuration/bundles/IBundles";
import {
  IDocumentMap,
  IDocumentMapsConfiguration,
} from "../../../models/configuration/importExport/IDocumentMap";
import { IDocumentMap as IDocumentMapConfig, IDocumentMappingConfiguration } from "../../../models/configuration/bundles/IDocumentMappings";
import { IntentToProceedIngestionOption, IPushbackConfiguration, ReceivedDatePopulation } from "../../../models/configuration/importExport/IPushbackConfiguration";
import ConfirmDialog from "../ConfirmDialog";
import { IImportExportPluginConfiguration } from "../../../models/configuration/importExport/IImportExportPluginConfiguration";
import { saveImportPluginConfig } from "../../../state/slices/importPluginConfigSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../state/store";
import { importIntegration } from "../../../state/slices/importIntegrationSlice";
import { saveImportPushbackConfig } from "../../../state/slices/importPushbackSlice";
import { importXpathFieldMap } from "../../../state/slices/importXpathFieldMapSlice";
import AlertSnackbar from "../AlertSnackbar";
import { importBundlingConfig } from "../../../state/slices/importBundlingSlice";
import { importEventBundlingConfig } from "../../../state/slices/importEventBundlingSlice";
import { importDocumentMappingConfig } from "../../../state/slices/importDocumentMappingSlice";

interface configTopBarProps {
  save: () => void;
  test?: () => void;
  error: string | null;
  reset?: () => void;
}

const ConfigTopBar: React.FC<configTopBarProps> = ({
  save,
  test,
  error,
  reset,
}) => {
  const { getConfigServerData, getConfigPlugInData, getConfigBundleDocumentData, getConfigBundleEventData, getConfigPushbackData, getConfigXPathFieldMapData, getDocumentMappingData, saveConfigBundleData, saveConfigBundleEventData, saveDocumentMappings } = useActions();
  const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);

  const hasFetchedData = useRef<boolean>(false);
  const { data: pluginConfigData } = useConfigurationData(ConfigurationDataType.PLUGIN_DATA);
  const { data: integrationData } = useTypedSelector((state) => state.configServers);
  const { data: documentBundleData } = useTypedSelector((state) => state.configDocumentBundles);
  const { data: eventBundleData } = useTypedSelector((state) => state.configEventBundles);
  const { data: pushbackData } = useTypedSelector((state) => state.configPushbackDefaults);
  const { data: xpathFieldMapData } = useTypedSelector((state) => state.configXPathFieldMap);
  const { data: documentMappingData } = useTypedSelector((state) => state.configDocumentMapping);

  const { loading: importPluginLoading } = useTypedSelector((state) => state.importPluginConfig);
  const { loading: importIntegrationLoading } = useTypedSelector((state) => state.importIntegration);
  const { loading: importPushbackLoading } = useTypedSelector((state) => state.importPushback);
  const { loading: xpathFieldMapLoading } = useTypedSelector((state) => state.importXpathFieldMap);
  const { loading: importDocumentMappingLoading } = useTypedSelector((state) => state.importDocumentMapping);
  const { loading: importBundlingLoading } = useTypedSelector((state) => state.importBundling);
  const { loading: importEventBundlingLoading } = useTypedSelector((state) => state.importEventBundling);

  const [loading, setLoading] = useState<boolean>(false);
  const [exportClicked, setExportClicked] = useState<boolean>(false);
  const [selectedConfigId, setSelectedConfigId] = useState<string | null>(null);
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<"success" | "error" | "info" | "warning">("success");
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (
      hasFetchedData.current && 
      exportClicked &&
      pluginConfigData &&
      integrationData &&
      documentBundleData &&
      eventBundleData &&
      pushbackData &&
      xpathFieldMapData &&
      documentMappingData
    ) {      
      saveConfigAsJson();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pluginConfigData,
    integrationData,
    documentBundleData,
    eventBundleData,
    pushbackData,
    xpathFieldMapData,
    documentMappingData,
  ]);

  useEffect(() => {
    const isLoading = importPluginLoading || importIntegrationLoading || importBundlingLoading || importEventBundlingLoading || importPushbackLoading || xpathFieldMapLoading || importDocumentMappingLoading;
    setImportLoading(isLoading);
  
    if (!isLoading && importLoading) {
      showAlert("Configuration imported successfully! Refresh the page to see the update", "success");
    }
  }, [
    importPluginLoading,
    importIntegrationLoading,
    importBundlingLoading,
    importEventBundlingLoading,
    importPushbackLoading,
    xpathFieldMapLoading,
    importDocumentMappingLoading,
    importLoading,
  ]);

  const fetchData = useCallback(
    async (configId: string, fetchFunction: (configId: string, accessToken: string | null) => void) => {
      fetchFunction(configId, accessToken);
    },
    [accessToken]
  );

  const getConfigId = () => {
    if (selectedConfigId) return selectedConfigId;
    return EncompassService.getConfigId() as string;
  };

  const initializeData = async () => {
    const configId = getConfigId();
    setSelectedConfigId(configId);
    await Promise.all([
      fetchData(configId, getConfigServerData),
      fetchData(configId, getConfigPlugInData),
      fetchData(configId, getConfigBundleDocumentData),
      fetchData(configId, getConfigBundleEventData),
      fetchData(configId, getConfigPushbackData),
      fetchData(configId, getConfigXPathFieldMapData),
      fetchData(configId, getDocumentMappingData),
    ]);
    hasFetchedData.current = true;
  };

  const exportConfig = async () => {
    setLoading(true);
    setExportClicked(true);
    await initializeData();    
  };

  const createModifiedData = () => {
    const modifiedIntegrationData = {
      ...integrationData,
      ConformX: { ...integrationData!.ConformX, ApiSecret: "" },
      Encompass: { ...integrationData!.Encompass, Password: "" },
    };

    const mapDocumentBundleData = (pkg: IDocumentBundle): IDocumentBundling => ({
      PackageId: pkg.PackageId ?? "",
      Signature: SignedOrUnsigned[pkg.BundlingConfigurationType as keyof typeof SignedOrUnsigned],
      BundlingOption: BundlingOption[pkg.BundlingOption as keyof typeof BundlingOption],
      OverrideConfig: pkg.DocumentHandlingPackageConfigs.length > 0,
      MergeList: pkg.MergerPackageConfigs.length > 0,
      DocumentHandlingPackageConfigs: pkg.DocumentHandlingPackageConfigs as IDocumentHandlingPackageConfiguration[],
      MergerPackageConfigs: pkg.MergerPackageConfigs as IMergerPackageConfiguration[],
      AllowMergerConfigDuplicate: pkg.MergerConfigDuplicate ?? false,
      AllowDocumentHandlingDuplicate: pkg.DocumentHandlingDuplicate ?? false,
    });

    const modifiedDocumentBundleData: IDocumentBundling[] = (
      documentBundleData?.Configuration?.Bundling ?? []
    ).map(mapDocumentBundleData);

    const modifiedEventBundleData: IEventsBundling[] = (
      eventBundleData?.Configuration?.Bundling ?? []
    ).map((event: IEventBundle): IEventsBundling => ({
      EventName: event.EventName ?? "",
      DocumentPackageHandling: (event.Configuration ?? []).map(mapDocumentBundleData),
    }));

    const modifiedDocumentMapData: IDocumentMapsConfiguration = {
      DateSaved: documentMappingData?.Configuration?.DateSaved ?? "",
      DocumentMaps: (
        documentMappingData?.Configuration?.DocumentMaps ?? []
      ).map((map: IDocumentMapConfig): IDocumentMap => ({
        index: map.DocumentIndex,
        Docutech: map.ConformXDocumentName,
        Encompass: map.EncompassDocumentName,
      })),
    };

    const modifiedPushbackData: IPushbackConfiguration = {
      IsDefault: pushbackData?.Configuration.IsDefault ?? false,
      ReceivedDatePopulation: ReceivedDatePopulation[pushbackData?.Configuration.ReceivedDatePopulation as keyof typeof ReceivedDatePopulation],
      UnnamedDocumentTitle: pushbackData?.Configuration.UnnamedDocumentTitle ?? "",
      MaxMessageRetryCount: Number(pushbackData?.Configuration.MaxMessageRetryCount) ?? 0,
      MessageRetryVisibilityTimeoutSeconds: Number(pushbackData?.Configuration.MessageRetryVisibilityTimeoutSeconds) ?? 0,
      LoanLockedVisibilityTimeoutSeconds: Number(pushbackData?.Configuration.LoanLockedVisibilityTimeoutSeconds) ?? 0,
      MaxConcurrency: Number(pushbackData?.Configuration.MaxConcurrency) ?? 0,
      DisclosureTrackingMapping: {
        IntentToProceed: {
          DocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.IntentToProceed?.DocumentIds ?? [],
          Ingestion: IntentToProceedIngestionOption[pushbackData?.Configuration.DisclosureTrackingMapping?.IntentToProceed?.Ingestion as keyof typeof IntentToProceedIngestionOption],
        },
        AffiliatedBusinessDisclosureDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.AffiliatedBusinessDisclosureDocumentIds ?? [],
        CHARMBookletDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.CHARMBookletDocumentIds ?? [],
        SpecialInfoBookletDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.SpecialInfoBookletDocumentIds ?? [],
        HELOCBrochureDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.HELOCBrochureDocumentIds ?? [],
        AppraisalDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.AppraisalDocumentIds ?? [],
        AVMDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.AVMDocumentIds ?? [],
        HomeCounselingDisclosureDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.HomeCounselingDisclosureDocumentIds ?? [],
        HighCostDisclosureDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.HighCostDisclosureDocumentIds ?? [],
        ProviderListDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.ProviderListDocumentIds ?? [],
        SafeHarborDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.SafeHarborDocumentIds ?? [],
        LoanEstimateDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.LoanEstimateDocumentIds ?? [],
        ClosingDisclosureDocumentIds: pushbackData?.Configuration.DisclosureTrackingMapping?.ClosingDisclosureDocumentIds ?? [],
      },
    };

    return {
      ...pluginConfigData,
      PluginConfiguration: pluginConfigData.Configuration,
      IntegrationApi: modifiedIntegrationData,
      DocumentBundling: modifiedDocumentBundleData,
      EventTypeBundling: modifiedEventBundleData,
      PushbackProcessing: modifiedPushbackData,
      XpathFieldMapping: xpathFieldMapData?.Configuration,
      DocumentMapping: modifiedDocumentMapData,
    };
  };

  const handleSnackBarClose = () => {
    setAlertOpen(false);
  };

  const showAlert = (message: string, severity: "success" | "error" | "info" | "warning") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  const saveConfigAsJson = () => {
    try {
      const modifiedData = createModifiedData();
      delete modifiedData.Configuration;
      const json = JSON.stringify(modifiedData, null, 2);
      
      const blob = new Blob([json], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "config.json";
      link.click();
      URL.revokeObjectURL(url);
      setExportClicked(false);
      setLoading(false);
      showAlert("Configuration exported successfully!", "success");
    } catch (error) {
      setExportClicked(false);
      setLoading(false);
      showAlert("Failed to export configuration.", "error");
    }
  };

  const importConfig = () => {    
    setOpenImport(true);
  };

  const mapDocumentBundle = (bundle: IDocumentBundling): IDocumentBundle => ({
    PackageId: bundle.PackageId,
    BundlingConfigurationType: SignedOrUnsigned[bundle.Signature],
    BundlingOption: BundlingOption[bundle.BundlingOption],
    MergerConfigDuplicate: bundle.AllowMergerConfigDuplicate,
    DocumentHandlingDuplicate: bundle.AllowDocumentHandlingDuplicate,
    OverrideConfigExists: bundle.OverrideConfig,
    DocumentHandlingPackageConfigs: bundle.DocumentHandlingPackageConfigs.map((config) => ({
      DocumentIndex: config.DocumentIndex,
      DocumentName: config.DocumentName,
      EncompassFolder: config.EncompassFolder,
      Suffix: config.Suffix,
      ReuseFolder: config.ReuseFolder,
      EncompassAttachment: config.EncompassAttachment,
    })),
    MergerPackageConfigs: bundle.MergerPackageConfigs.map((config) => ({
      MergeList: config.MergeList,
      EncompassFolder: config.EncompassFolder,
      Suffix: config.Suffix,
      ReuseFolder: config.ReuseFolder,
      EncompassAttachment: config.EncompassAttachment,
    })),
  });

  const importConfigHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const configId = getConfigId();
    const file = event.target.files?.[0];
    if (file && file.type === "application/json") {      
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const parsedConfig = JSON.parse(e.target?.result as string) as IImportExportPluginConfiguration;            
          if(parsedConfig.PluginConfiguration) {
            dispatch(saveImportPluginConfig({ configurationId: configId, config: parsedConfig.PluginConfiguration }));
          }
          if(parsedConfig.IntegrationApi) {           
            dispatch(importIntegration({ configId: configId, integration: parsedConfig.IntegrationApi }));
          }
          if(parsedConfig.DocumentBundling) {
            const documentBundles: IDocumentBundle[] = parsedConfig.DocumentBundling.map(mapDocumentBundle);
            dispatch(importBundlingConfig({ configurationId: configId, config: documentBundles }));
          }
          if (parsedConfig.EventTypeBundling) {            
            const eventBundles: IEventBundle[] = parsedConfig.EventTypeBundling.map(event => ({
              EventName: event.EventName,
              Configuration: event.DocumentPackageHandling.map(mapDocumentBundle),
            }));
            dispatch(importEventBundlingConfig({ configurationId: configId, config: eventBundles }));
          }
          if(parsedConfig.PushbackProcessing) {            
            const updatedPushbackDefaultData: IPushbackConfiguration = {
              ...parsedConfig.PushbackProcessing,
              ReceivedDatePopulation: ReceivedDatePopulation[parsedConfig.PushbackProcessing.ReceivedDatePopulation as unknown as keyof typeof ReceivedDatePopulation],
              DisclosureTrackingMapping: {
                ...parsedConfig.PushbackProcessing.DisclosureTrackingMapping,
                IntentToProceed: {
                  DocumentIds: parsedConfig.PushbackProcessing.DisclosureTrackingMapping.IntentToProceed.DocumentIds,
                  Ingestion: IntentToProceedIngestionOption[parsedConfig.PushbackProcessing.DisclosureTrackingMapping.IntentToProceed.Ingestion as unknown as keyof typeof IntentToProceedIngestionOption],
                },
              },
            }
            dispatch(saveImportPushbackConfig({ configurationId: configId, config: updatedPushbackDefaultData }));
          }
          if(parsedConfig.XpathFieldMapping) {           
            dispatch(importXpathFieldMap({ configurationId: configId, xPathfieldMap: parsedConfig.XpathFieldMapping }));
          }
          if(parsedConfig.DocumentMapping) {
            const updatedDocumentMappingData: IDocumentMappingConfiguration = {
              configurationId: configId,
              Configuration: {
                DateSaved: parsedConfig.DocumentMapping.DateSaved ?? new Date().toUTCString(),
                DocumentMaps: parsedConfig.DocumentMapping.DocumentMaps.map((map) => ({
                  DocumentIndex: map.index,
                  ConformXDocumentName: map.Docutech,
                  EncompassDocumentName: map.Encompass,
                })),
              },
            }
            dispatch(importDocumentMappingConfig({ configurationId: configId, config: updatedDocumentMappingData }));
          }
        } catch (error) {
          console.error("Error parsing JSON file", error);
          showAlert("Failed to import configuration.", "error");
        }
      };
      reader.readAsText(file);
    } else {
      console.error("Please select a valid JSON file.");
      showAlert("Please select a valid JSON file.", "error");
    }
  };

  return (
    <div>
      {loading && (
        <ConfigLoad loading={loading} message="Exporting Configuration" />
      )}
      {importLoading && (
        <ConfigLoad loading={importLoading} message="Importing Configuration" />
      )}
      <Card sx={{ height: "65px", margin: 2 }}>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              {reset && (
                <Button
                  sx={{ marginLeft: ".5rem" }}
                  onClick={reset}
                  variant="contained"
                  size="small"
                >
                  Reset
                </Button>
              )}
              {test && (
                <Button
                  sx={{ marginLeft: ".5rem", backgroundColor: "orange" }}
                  onClick={test}
                  variant="contained"
                  size="small"
                >
                  Test
                </Button>
              )}
              {error && (
                <Tooltip title={error} arrow>
                  <Typography
                    sx={{
                      width: "100%",
                      fontSize: ".7rem",
                      flex: 1,
                      marginLeft: "1rem",
                      padding: 1,
                      border: "1px solid black",
                      borderRadius: "2px",
                    }}
                    variant="body1"
                    color="error"
                  >
                    {error}
                  </Typography>
                </Tooltip>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                sx={{ marginRight: ".5rem" }}
                onClick={exportConfig}
                variant="contained"
                size="small"
              >
                Export
              </Button>
              <Button onClick={importConfig} variant="contained" size="small">
                Import
              </Button>
              <input
                type="file"
                accept="application/json"
                style={{ display: "none" }}
                id="import-config-file"
                onChange={(event) => {
                  importConfigHandler(event);
                  event.target.value = "";
                }}
              />
            </Box>
          </Box>
          <ConfirmDialog
            open={openImport}
            title="Import Warning"
            message="Importing a configuration will overwrite any configuration that is saved. Press 'Ok' to continue."
            onConfirm={() => {
              setOpenImport(false);
              document.getElementById('import-config-file')?.click();
            }}
            onCancel={() => setOpenImport(false)}
            confirmName="Yes"
            cancelName="No"
          />
        </CardContent>
      </Card>
      <AlertSnackbar
        alertOpen={alertOpen}
        alertMessage={alertMessage}
        alertSeverity={alertSeverity}
        handleSnackBarClose={handleSnackBarClose}
      />
    </div>
  );
};

export default ConfigTopBar;
