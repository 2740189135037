import React, { useCallback, useState, useEffect, useRef, ChangeEvent, useMemo } from "react";
import MuiAlert from '@mui/material/Alert';
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ThemeProvider, IconButton, Button, Box, Typography, TextField, Select, MenuItem, Snackbar, Card, CardHeader, CardContent, SelectChangeEvent } from "@mui/material";
import colorTheme from "../../assets/colorTheme";
import "../../assets/style.css";
import { useActions } from "../../hooks/useActions";
import PluginService from "../../services/pluginService";
import IIntegration from "../../models/configuration/servers/IIntegration";
import EncompassService from "../../services/encompassService";
import { useSelector } from "react-redux";
import { RootState, store } from "../../state/store";
import ConfirmationDialog from "../../components/shared/ConfirmDialog";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";
import { IImpersonation } from "../../models/configuration/plugin/IConfiguration";
import { ConfigurationDataType, useConfigurationData } from "../../hooks/useConfigurationData";
import { setClientCode, setServerUris, setConfigId } from "../../state/appSlice";
import { AlertSeverity } from "../../constants/AlertTypes";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Tooltip from '@mui/material/Tooltip';

const ENCOMPASS_REQUEST_API_URI = process.env.REACT_APP_DT_REQUEST_API_URI;
const ENCOMPASS_PLUGIN_API_URI = process.env.REACT_APP_DT_PLUGIN_API_URI;
const ENCOMPASS_PUSHBACK_API_URI = process.env.REACT_APP_DT_PUSHBACK_API_URI;
const ENCOMPASS_TOKEN_ENDPOINT_API_URI = process.env.REACT_APP_DT_TOKEN_ENDPOINT_API_URI;

const ConfigServers: React.FC = () => {
    interface IConfigurationIds { ConfigurationIds: string[] };

    // Reducers
    const { 
        getConfigServerData, 
        saveConfigServerData, 
        saveConfigImpersonationData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configServers);
    const configData = useConfigurationData(ConfigurationDataType.IMPERSONATION);
    const [integrationData, setIntegrationData] = useState<IIntegration | null>(data || null);
    const [impersonationDataState, setImpersonationDataState] = useState<IImpersonation | null>(null);
    const [saving, setSaving] = useState<boolean>(false);

    // State
    const [configIds, setConfigIds] = useState<string[]>([]);
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');
    const [requestUri, setRequestUri] = useState('');
    const [pluginUri, setPluginUri] = useState('');
    const [pushbackUri, setPushbackUri] = useState('');
    const [tokenUri, setTokenUri] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogTitle] = useState("Are you sure?");
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogAction, setDialogAction] = useState<() => void>(() => []);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [apiKeyIsDisabled, setApiKeyIsDisabled] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

    // Refs
    const hasFetchedData = useRef(false);
    const hasFetchedConfigIds = useRef(false);

    // Services 
    const pluginService = useMemo(() => new PluginService(), []);
    const { data: impersonationData, error: impersonationError } = configData
    const impersonationMemoData = useMemo(() => impersonationData, [impersonationData]);

    // Fetch Page Data
    const fetchServerConfigs = useCallback(async (configId: string) => {
        getConfigServerData(configId, accessToken);
    }, [getConfigServerData, accessToken]);

    // Save Page Data
    const pushConfigServerData = useCallback(async (configId: string, data: IIntegration) => {
        saveConfigServerData(configId, { ...data }, accessToken);
    }, [saveConfigServerData, accessToken]);

    const fetchConfigIds = useCallback(async (configId: string) => {
        const cId = getConfigId();
        if (!cId)
            throw new Error("No valid configuration ID found.");

        try {
            const response = await pluginService.getConfig<IConfigurationIds>(accessToken, "ids", cId);
            if (response && response.ConfigurationIds) {
                setConfigIds(response.ConfigurationIds);
            }
        } catch (error) {
            console.error("Error fetching configuration IDs:", error);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginService, accessToken]);

    const initializeData = () => {
        const configId = getConfigId();
        setSelectedConfigId(configId);
        const fetchData = async () => {
            await Promise.all([
                fetchServerConfigs(configId),
                fetchConfigIds(configId)
            ]);
            //await fetchImpersonationData(configId); // Fetch impersonation data after other data
            hasFetchedData.current = true;
            hasFetchedConfigIds.current = true;
        };
        fetchData();
    }

    // Mount control
    useEffect(() => {
        if (!hasFetchedData.current && !data) {
            initializeData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Handle Impersonation Data
    useEffect(() => {
        if (impersonationMemoData != null) {
            setImpersonationDataState(impersonationMemoData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impersonationMemoData]);


    // Handle Data 
    useEffect(() => {
        if (!saveError && data != null) {
            setIntegrationData(data); // Only update local data if there is no save Error condition 
            initializeScreenData();
            fetchConfigIds(getConfigId());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, saveError]);

    function initializeScreenData() {
        const requestUri = localStorage.getItem("requestUri") || ENCOMPASS_REQUEST_API_URI as string;
        const pluginUri = localStorage.getItem("pluginUri") || ENCOMPASS_PLUGIN_API_URI as string;
        const pushbackUri = localStorage.getItem("pushbackUri") || ENCOMPASS_PUSHBACK_API_URI as string;
        const tokenUri = localStorage.getItem("tokenUri") || ENCOMPASS_TOKEN_ENDPOINT_API_URI as string;
        setRequestUri(requestUri);
        setPluginUri(pluginUri);
        setPushbackUri(pushbackUri);
        setTokenUri(tokenUri);
        store.dispatch(setServerUris({ 
            encompassRequestUri : requestUri, 
            encompassPluginUri : pluginUri, 
            encompassPushbackUri : pushbackUri, 
            encompassTokenUri:  tokenUri }));
        if (!selectedConfigId) {
            setSelectedConfigId(getConfigId())
        }
    }

    const save = async () => {
        cacheEndpoints();
        try {
            setSaving(true);
            openAlert("Saving Server Changes", "info");
            pushConfigServerData(getConfigId(), { ...integrationData } as IIntegration);
            updateImpersonationData();
            openAlert("Successfully Saved Config Server Changes", "success");
            setSaveError(false);
        } catch (e) {
            console.error("Error saving data");
            setSaveError(true);
            setSaving(false);
        } finally {
            setSaving(false);
        }
    }

    const updateImpersonationData = () => {
        if (impersonationDataState?.ClientCode) {
            saveConfigImpersonationData({ 
                ClientCode: impersonationDataState.ClientCode, 
                EnableImpersonation: impersonationDataState.EnableImpersonation 
            });
        }
    };

    useEffect(() => {
        if (selectedConfigId) {
            hasFetchedData.current = false;
            hasFetchedConfigIds.current = false;
            localStorage.setItem('configurationId', selectedConfigId);
            initializeData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedConfigId]);


    const handleConfigChange = (event: SelectChangeEvent) => {
        const newConfigId = event.target.value as string;
        setSelectedConfigId(newConfigId);
        store.dispatch(setConfigId(newConfigId));
    };

    async function TestEncompassConnection(ApiEndpointUrl: any): Promise<void> {
        try {
            fetchConfigIds(getConfigId()); // Using Fetch ConfigId's to test encompass token
            openAlert("Token retrieval Successful, Api communication successful", "success");
            ResetErrors();
            await new Promise(resolve => setTimeout(resolve, 3000));
            await save();
        } catch (error) {
            console.log(error);
            openAlert("Failed to retrieve auth token, Check OAuth Configuration", "error");
        }
    }

    const getConfigId = () => {
        if (selectedConfigId)
            return selectedConfigId;
        return EncompassService.getConfigId() as string;
    }

    const ResetErrors = () => {
        setErrorMessage('');
        setSaveError(false);
    }

    useEffect(() => {
        if (requestUri || pluginUri || tokenUri) {
            cacheEndpoints();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestUri, pluginUri, tokenUri]);

    // Dialog Handling 
    const handleOpenDialog = (message: string, action: () => void) => {
        setDialogMessage(message);
        setDialogAction(() => action);
        setIsDialogOpen(true);
    }

    const handleConfirm = () => {
        setIsDialogOpen(false);
        dialogAction();
    };

    const handleCancel = () => {
        setIsDialogOpen(false);
    };

    function cacheEndpoints() {
        localStorage.setItem('requestUri', requestUri);
        localStorage.setItem('pluginUri', pluginUri);
        localStorage.setItem('pushbackUri', pushbackUri);
        localStorage.setItem('tokenUri', tokenUri);
    }

    // Handle Changes to local state based elements using input name as the key
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setIntegrationData((prevState) => {
            if (!prevState) return prevState;
            const keys = name.split('.');
            const newState = { ...prevState };
            let currentLevel: any = newState;

            // Iterate through the value using the name of the inputs 
            for (let i = 0; i < keys.length - 1; i++) {
                currentLevel[keys[i] as keyof typeof currentLevel] = {
                    ...currentLevel[keys[i] as keyof typeof currentLevel],
                };
                currentLevel = currentLevel[keys[i] as keyof typeof currentLevel];
            }
            currentLevel[keys[keys.length - 1] as keyof typeof currentLevel] = value;
            return newState;
        });
    };

    const handleClientCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setImpersonationDataState({ 
            ...impersonationDataState, 
            ClientCode: event.target.value, 
            EnableImpersonation: impersonationDataState?.EnableImpersonation ?? false 
        });
        store.dispatch(setClientCode(event.target.value)); 
    }

    const handleUriChange = (
        setter: React.Dispatch<React.SetStateAction<string>>
    ) => (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <ThemeProvider theme={colorTheme}>
            <>
                <section>

                    <div>
                        {(loading || saving) && <ConfigLoad loading={loading} />}
                    </div>
                    <div>
                        <Box
                            mt={1}
                            component="main"
                            style={{}}
                            sx={{
                                backgroundColor: (theme) =>
                                    theme.palette.mode === 'light'
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[900],
                                flexGrow: 1,
                                overflow: "auto",
                                minHeight: '100vh'
                            }}
                        >
                            <ConfigTopBar save={save} error={error || errorMessage || impersonationError || null} />
                            <Card sx={{ p: 2, display: 'flex', flexDirection: 'column', height: "auto", margin: 2 }}>
                                <CardHeader title="ConformX Server Configuration"></CardHeader>

                                <CardContent>
                                    <div className="configServersContainerConformX">

                                        {impersonationDataState?.EnableImpersonation && (
                                            <div>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start" }}>
                                                    <Typography width="13rem" variant="subtitle1">Client Code:</Typography>
                                                    <TextField
                                                        fullWidth variant="outlined" size="small" value={impersonationDataState.ClientCode || ''}
                                                        onChange={handleClientCodeChange} />
                                                </Box>

                                                <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                                    <Typography width="13rem" variant="subtitle1">ConfigurationId:</Typography>
                                                    <Select fullWidth variant="outlined" defaultValue="" size="small"
                                                        value={configIds.includes(selectedConfigId) ? selectedConfigId : ''}
                                                        onChange={handleConfigChange} >
                                                        {configIds.map((id) => (
                                                            <MenuItem
                                                                key={id}
                                                                sx={{ padding: '2px 8px' }}
                                                                value={id}> {id}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </Box>
                                            </div>
                                        )}

                                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                            <Typography width="13rem" variant="subtitle1">URI:</Typography>
                                            <TextField
                                                name="ConformX.ApiEndpointUrl"
                                                value={integrationData?.ConformX?.ApiEndpointUrl || ''}
                                                onChange={handleChange}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                            />
                                        </Box>

                                        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                            <Typography width="13rem" variant="subtitle1">API Key ID:
                                                <Tooltip title="Edit API Key field to view API Secret field">
                                                    <IconButton edge="end" aria-label="Edit">
                                                        <InfoOutlinedIcon style={{ marginLeft: "3px" }} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                            <TextField
                                                name="ConformX.ApiKey"
                                                onChange={handleChange}
                                                value={integrationData?.ConformX?.ApiKey || ''}
                                                variant="outlined"
                                                size="small"
                                                disabled={apiKeyIsDisabled}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <IconButton edge="end" aria-label="Edit"
                                                            onClick={() => { setApiKeyIsDisabled(false) }} >
                                                            {apiKeyIsDisabled && <EditOutlinedIcon />}
                                                        </IconButton>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                        {!apiKeyIsDisabled &&
                                            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                                <Typography width="13rem" variant="subtitle1">API Key Secret:</Typography>
                                                <TextField
                                                    name="ConformX.ApiSecret"
                                                    onChange={handleChange}
                                                    value={integrationData?.ConformX?.ApiSecret || ''}
                                                    fullWidth
                                                    variant="outlined"
                                                    type={showPassword ? 'text' : 'password'}
                                                    size="small"
                                                    disabled={apiKeyIsDisabled}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton edge="end" aria-label="Show/Hide"
                                                                onClick={() => { setShowPassword(!showPassword) }}>
                                                                {showPassword ?
                                                                    <VisibilityOffOutlinedIcon />
                                                                    : <VisibilityOutlinedIcon />
                                                                }
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                        }
                                    </div>
                                </CardContent>
                            </Card>


                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: "auto",
                                    margin: 2
                                }}
                            >
                                <CardHeader title="Encompass Configuration" />

                                <CardContent>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                        <Typography width="13rem" variant="subtitle1">Configuration ID:</Typography>
                                        <TextField
                                            disabled={true}
                                            fullWidth variant="outlined" size="small"
                                            value={selectedConfigId}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                        <Typography width="13rem" variant="subtitle1">Request API URI:</Typography>
                                        <TextField
                                            onChange={handleUriChange(setRequestUri)}
                                            fullWidth variant="outlined" size="small"
                                            value={requestUri} />

                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                        <Typography width="13rem" variant="subtitle1">Plugin API URI:</Typography>
                                        <TextField fullWidth variant="outlined" size="small"
                                            onChange={handleUriChange(setPluginUri)}
                                            value={pluginUri}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                        <Typography width="13rem" variant="subtitle1">Pushback API URI:</Typography>
                                        <TextField fullWidth variant="outlined" size="small"
                                            onChange={handleUriChange(setPushbackUri)}
                                            value={pushbackUri}
                                        />
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                        <Typography width="13rem" variant="subtitle1">Token Endpoint URI:</Typography>
                                        <TextField
                                            onChange={handleUriChange(setTokenUri)}
                                            fullWidth variant="outlined" size="small"
                                            value={tokenUri}
                                        />

                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: "1rem" }}>
                                        <Button
                                            onClick={() => handleOpenDialog(
                                                'Testing this Connection will save the configuration, do you want to proceed?',
                                                () => TestEncompassConnection(requestUri)
                                            )}
                                            variant="contained" size="small" >
                                            Test Connection
                                        </Button>

                                    </Box>

                                    <Snackbar
                                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        open={alertOpen}
                                        autoHideDuration={8000}
                                        onClose={handleSnackBarClose}
                                    >
                                        <MuiAlert
                                            onClose={handleSnackBarClose}
                                            severity={alertSeverity}
                                            sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                                            {alertMessage}
                                        </MuiAlert>
                                    </Snackbar>

                                    <ConfirmationDialog
                                        open={isDialogOpen}
                                        title={dialogTitle}
                                        message={dialogMessage}
                                        onConfirm={handleConfirm}
                                        onCancel={handleCancel}
                                    />

                                </CardContent>
                            </Card>
                        </Box>
                        <ConfigBottomBar save={save} />
                    </div>

                </section>
            </>
        </ThemeProvider >
    )
}

export default ConfigServers;