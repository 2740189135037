import React, { useState } from "react";
import { ThemeProvider, Checkbox, Table, TableRow, TableCell, TableHead, TableContainer, TableBody, Typography, Select, MenuItem, SxProps, SvgIcon, TableSortLabel, FormControl, Box, Pagination } from "@mui/material";
import colorTheme from "../../../assets/colorTheme";
import { IDataColumn, DTDataGridColumnType, DTDataGridAlignment } from "../../../models/IDataColumn";
import DTDataGridTextEdit from "./DTDataGridTextEdit";
import "../../../assets/style.css";

interface DataGridProps {
  columns: Array<IDataColumn>;
  data: Array<any>;
  tableName: string;
  containerSx?: SxProps | undefined;
  dense?: boolean;
  paged?: boolean;
  onRowClick?: (rowIndex: number) => void;
}

const DTDataGrid: React.FC<DataGridProps> = (props: DataGridProps) => {
  const [sortedCol, setSortedCol] = useState(-1);
  const [isSorted, setIsSorted] = useState(false);
  const [sortDirs, setSortDirs] = useState<Array<boolean>>([]);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  // pagination
  const rowsPerPage = 15;
  const [currPage, setCurrPage] = useState(1);

  function getCellValue(row: any, col: IDataColumn) {
    if (col.dataFunction != null) {
      return col.dataFunction(row);
    }

    return getProperty(row, col.fieldId) ?? "";
  }

  function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName];
  }

  const setColumnSort = (index: number) => {
    let arsort: Array<boolean> = [];
    if (sortDirs == null || sortDirs.length === 0) {
      props.columns?.map((col, i) => arsort.push(false));
    } else {
      arsort = sortDirs;
    }

    arsort[index] = !arsort[index];
    setSortDirs([...arsort]);
    setSortedCol(index);
    setIsSorted(true);
  };

  const getSortedItems = () => {
    let ret = props.data;

    if (isSorted && sortedCol > -1) {
      const sortCol = props.columns[sortedCol].fieldId;
      const sortOrd = sortDirs[sortedCol];
      ret = sortByColumn(props.data, sortCol, sortOrd);
    }

    if (props.paged === true) {
      return ret.slice((currPage - 1) * rowsPerPage, currPage * rowsPerPage);
    }

    return ret;
  };

  function sortByColumn(data: Array<any>, column: string, sortDirAsc: boolean) {
    // Create a copy of the data array to avoid state mutation
    const dataCopy = [...data];
    return dataCopy.sort(function (element1, element2) {
      const columnVal1 = element1[column].toString().trim().toLowerCase();
      const columnVal2 = element2[column].toString().trim().toLowerCase();
      return (columnVal1 < columnVal2 ? -1 : columnVal1 > columnVal2 ? 1 : 0) * (sortDirAsc ? 1 : -1);
    });
  }

  const handlePageChange = (e: any, value: any) => {
    if (value < 0) return;
    setCurrPage(value);
  };

  const handleRowClick = (rowIndex: number) => {
    setSelectedRow(rowIndex);
    if (props.onRowClick) {
      props.onRowClick(rowIndex);
    }
  };

  const getConditionalForeColor = (col: IDataColumn, item: any) => {
    if (col.foreColorFunction != null) {
      return col.foreColorFunction(item);
    }
    return col.foreColor;
  }
  const getConditionalBackColor = (col: IDataColumn, item: any) => {
    if (col.backColorFunction != null) {
      return col.backColorFunction(item);
    }
    return "";
  }

  function getTableCell(
    row: any,
    col: IDataColumn,
    rowindex: number,
    colindex: number
  ) {
        let cellKey = ["tc", rowindex.toString(), colindex.toString()].join("");
    if (col.datatype === DTDataGridColumnType.text) {
      return (
          <DTDataGridTextEdit
            key={cellKey}
            itemName={col.fieldId}
            text={getCellValue(row, col)}
            cellKey={cellKey}
            alignmentClass={getCellClass(col)}
            dataChangeFunction={col.dataChangeFunction}
            rowIndex={row}
            editOnClick={col.editOnClick}
            foreColor={getConditionalForeColor(col, row) ?? undefined}
            backColor={getConditionalBackColor(col, row) ?? undefined}
          />
        
      );
    }
    if (col.datatype === DTDataGridColumnType.link) {
      return (
        <TableCell
          key={cellKey}
          sx={{
            color: col.foreColor,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          className=""
          tabIndex={0}
        >
          <div
            className={getCellClass(col)}
            onClick={(e) =>
              col.callbackFunction ? col.callbackFunction(e, row) : null
            }
          >
            {col.linkText} 
          </div>
        </TableCell>
      );
    }
    if (col.datatype === DTDataGridColumnType.checkbox) {
      return (
        <TableCell key={cellKey} tabIndex={0}>
          <div className={getCellClass(col)}>
            <Checkbox
              size="small"
              name={col.fieldId}
              checked={getCellValue(row, col)}
              onChange={(e) =>
                col.dataChangeFunction ? col.dataChangeFunction(e, row) : null
              }
            />
          </div>
        </TableCell>
      );
    }
    if (col.datatype === DTDataGridColumnType.image) {
      return (
        <TableCell key={cellKey} tabIndex={0} >
          <div
            className={getCellClass(col)}
            onClick={(e) =>
              col.callbackFunction ? col.callbackFunction(e, row) : null
            }
          >
            <SvgIcon
              sx={{ color: col.foreColor, cursor: "pointer" }}
              component={col.displayImage!}
            />
          </div>
        </TableCell>
      );
    }

    if (col.datatype === DTDataGridColumnType.dropdownlist) {
      return (
        <TableCell key={cellKey} className={getCellClass(col)} tabIndex={0} >
          <FormControl
            variant="filled"
            fullWidth
            sx={{ m: 0, minWidth: "32px" }}
            size="small"
          >
            <Select
              // label={col.name ?? ""}
              name={col.fieldId}
              value={getCellValue(row, col)}
              onChange={(e) =>
                col.dataChangeFunction ? col.dataChangeFunction(e, row) : null
              }
              fullWidth
              variant="outlined"
              displayEmpty
              margin="dense"
            >
              {col.dataOptions?.map((opt, i) => {
                return (
                  <MenuItem
                    key={[
                      "mi",
                      colindex.toString(),
                      rowindex.toString(),
                      i.toString(),
                    ].join("")}
                    value={opt}
                  >
                    {opt}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </TableCell>
      );
    }
  }

  function getTableHeaderCell(col: IDataColumn, index: number) {
    return (
      <TableCell component="th" scope="row" key={index}>
        {col.sortable ? (
          <TableSortLabel
            active={sortedCol === index}
            direction={sortDirs[index] ? "asc" : "desc"}
            onClick={() => setColumnSort(index)}
          >
            {getCellDiv(col)}
          </TableSortLabel>
        ) : (
          getCellDiv(col)
        )}
      </TableCell>
    );
  }

  const getCellDiv = (col: IDataColumn) => {
    return (
      <div className={getHeaderClass(col)}>
        <Typography fontWeight={"fontWeightBold"}>{col.name}</Typography>
      </div>
    );
  };

  function getAlignmentClass(col: IDataColumn) {
    if (col.alignment == null || col.alignment === DTDataGridAlignment.left) {
      return "flexLeft";
    }
    if (col.alignment === DTDataGridAlignment.right) {
      return "flexRight";
    }
    if (col.alignment === DTDataGridAlignment.center) {
      return "flexCenter";
    }
    return "";
  }

  function getHeaderClass(col: IDataColumn) {
    let classes: Array<string> = [];
    classes.push(getAlignmentClass(col));

    if (col.headerClass) {
      classes.push(col.headerClass);
    }
    return classes.join(" ");
  }

  function getCellClass(col: IDataColumn) {
    let classes: Array<string> = [];
    classes.push(getAlignmentClass(col));
    if (col.datatype !== DTDataGridColumnType.checkbox) {
      classes.push("dtcell");
    }

    if (col.cellClass) {
      classes.push(col.cellClass);
    }
    return classes.join(" ");
  }

  return (
    <>
      {props !== null}{" "}
      {
        <ThemeProvider theme={colorTheme}>
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <TableContainer component="main" sx={props.containerSx}>
                <Table
                  stickyHeader
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    overflow: "auto",
                    height: "100%",
                    maxHeight: "10vh",
                  }}
                  size={props.dense ? "small" : "medium"}
                >
                  <TableHead
                    sx={{
                      background: "lightgrey",
                      fontWeight: "bold",
                    }}
                  >
                    <TableRow hover>
                      {props.columns?.map((col, i) =>
                        getTableHeaderCell(col, i)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSortedItems()?.map((row: Array<any>, r) => (
                      <TableRow
                        hover
                        key={r}
                        onClick={() => handleRowClick(r)}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          backgroundColor: selectedRow === r ? "lightblue" : "inherit",
                        }}
                      >
                        {props.columns?.map((col: IDataColumn, c) =>
                          getTableCell(row, col, r, c)
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {props.paged === true ? (
                <Box mt={1} className="flexRight">
                  <Pagination
                    count={Math.ceil(props.data.length / rowsPerPage)}
                    page={currPage}
                    onChange={handlePageChange}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Box>
          </>
        </ThemeProvider>
      }
    </>
  );
};

export default DTDataGrid;