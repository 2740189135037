import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IPushbackConfiguration, IMessageType } from "../../models/configuration/pushback/IConfiguration";

// This action creator gets the Default values from an api call or state
 const getConfigPushbackData = (
  configId: string,
  token: string | null
): ((dispatch: Dispatch<Action>, getState: () => any) => Promise<void>) => {
  let status: IMessageType = { Type: "", Message: [] };
  return async (dispatch: Dispatch<Action>, getState: () => any) => {
    dispatch({ type: ActionType.GET_CONFIG_PUSHBACK });

    try {
      const { data: pushbackConfiguration } = getState().configPushbackDefaults;

      if (pushbackConfiguration !== null) {
        dispatch({
          type: ActionType.GET_CONFIG_PUSHBACK_SUCCESS,
          payload: pushbackConfiguration,
        });
      } else {
        const payload =
          await new PluginService().getConfig<IPushbackConfiguration>(
            token,
            "pushback-processor",
            configId
          );
        dispatch({
          type: ActionType.GET_CONFIG_PUSHBACK_SUCCESS,
          payload: payload,
        });
      }
    } catch (error: any) { 
      console.error("Get Default Values Data");
      if (error instanceof Error) {
        const payloadErrorMessage = `Failed to fetch Default Values Data: (${error.message})`;
        status.Type = "error";
        status.Message = [payloadErrorMessage];
        dispatch({
          type: ActionType.GET_CONFIG_PUSHBACK_FAILURE,
          payload: payloadErrorMessage,
        });
        return Promise.reject(status);
      } 
    }
  };
};

export default getConfigPushbackData;