import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from "@mui/material";
import { store } from "../../state/store";
import { ITransactionRequest } from "../../models/ITransactionRequest";
import { API_REQUEST_STATUS, EPC_REQUEST_TYPES } from "../../utils/constants";
import ElliHost from "../../services/elliHost";
import { useDispatch } from "react-redux";
import { setLoading } from "../../state/appSlice";
import DocListApiService from "../../services/DocListApiService";
import { IDocListResponse } from "../../models/IDocListResponse";

const RefreshDocsDialog =
    (props: {
        confirmUpdateOpen: any,
        handleConfirmClose: any,
        packageType: string,
        altPackageId: string,
        parentPackageId: string,
        handleUpdatedDocs: any
    }) => {
        const elliHost = ElliHost.getInstance();
        const dispatch = useDispatch();

        const refreshDocs = async () => {
            dispatch(setLoading(true));
            props.handleConfirmClose(false)

            const currentState = store.getState();
            let request: ITransactionRequest = {
                //TODO: Change to SelectTOPrint type when implemented in EPC
                type: EPC_REQUEST_TYPES.DISCLOSURES,
                options: {
                    RequestType: "DocList",
                    DocListRequest: {
                        LoanId: currentState.appSlice.loanId ?? "",
                        ConfigurationId: currentState.appSlice.configId ?? "",
                        RequestOptions: {
                            PackageType: props.packageType,
                            ImpersonationOption: {
                                ClientCode: currentState.appSlice.clientCode ?? "",
                                EnableImpersonation: currentState.appSlice.clientCode ? true : false
                            }
                        }
                    }
                }
            }

            if (props.altPackageId) {
                if (!isNaN(Number(props.altPackageId))) {
                    request.options.DocListRequest!.RequestOptions.AlternatePackageType = props.altPackageId;
                    request.options.DocListRequest!.RequestOptions.PackageType = props.parentPackageId;
                }
            }

            const transactionId = await elliHost.createTransaction(request);
            const docListResult = await pollDocList(transactionId.id, currentState.appSlice.accessToken ?? "") 
            dispatch(setLoading(false));
            props.handleUpdatedDocs(docListResult);

        }

        const pollDocList = async (transactionId: string, accessToken: string) => {
            const waitInterval = 5000;
            const maxPollTimes = 60;
            let pollCount = 0;
            const dlService = new DocListApiService();
            let keepPolling = true;
            while (keepPolling && pollCount < maxPollTimes) {
                const response: IDocListResponse | void = await dlService.pollDocList(transactionId, accessToken ?? "")
                    .catch(e => { console.log(e) }); 
                if(response && (response.result.requestStatus === API_REQUEST_STATUS.COMPLETE || response.result.requestStatus === API_REQUEST_STATUS.ERROR)){
                    return response;
                }
                //if not satisfactory result, keep going.
                pollCount++;
                await new Promise(resolve => setTimeout(resolve, waitInterval));
            }
            return null;
        }

        return (
            <Dialog open={props.confirmUpdateOpen} onClose={props.handleConfirmClose}>
                <DialogTitle>
                    Retrieve Documents
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography component={'span'}>Retrieving Select to Print documents can take some time to complete.</Typography>
                        <Typography component={'span'}>Would you like to proceed? </Typography>
                    </DialogContentText>
                    <DialogActions>
                        <Button variant="contained" size="small" color="error" onClick={() => { props.handleConfirmClose(false) }}>No</Button>
                        <Button variant="contained" size="small" onClick={async () => { await refreshDocs() }}>Yes</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
export default RefreshDocsDialog;