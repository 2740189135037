import { IPackageTypes } from "../../../models/request/IRequest";
import { Action, ActionType } from "../../actions";

interface RequestPackageTypesState {
  loading: boolean;
  error: string | null;
  data: IPackageTypes | null;
}

const initialState = { loading: false, error: null, data: null };

const requestPackageTypesReducer = (
  state: RequestPackageTypesState = initialState,
  action: Action
): RequestPackageTypesState => {
  switch (action.type) {
    // GET DATA
    case ActionType.GET_REQUEST_PACKAGE_TYPES:
      return { ...state, loading: true, error: null };
    case ActionType.GET_REQUEST_PACKAGE_TYPES_FAILURE:
        return { ...state, loading: false, error: action.payload };
    case ActionType.GET_REQUEST_PACKAGE_TYPES_SUCCESS:
        return { ...state, loading: false, error: null, data: action.payload };
    default:
        return state;
  }
};

export default requestPackageTypesReducer;