import React, { useEffect, useState, useRef } from 'react';
import {
    TextField, FormControlLabel, Box, Radio, Dialog, DialogTitle,
    DialogContent, DialogActions, Button, Typography, List, ListItem
} from '@mui/material';
import { IDisclosureTrackingMapping } from '../../../models/configuration/plugin/IConfiguration';
import { ConfigurationDataType, useConfigurationData } from '../../../hooks/useConfigurationData';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DisclosureTrackingMappingsDialogProps {
    open: boolean;
    closeFxtn: () => void;
    updateData: (data: IDisclosureTrackingMapping) => void;
}

const DisclosureTrackingMappingsDialog: React.FC<DisclosureTrackingMappingsDialogProps> = ({ open, closeFxtn, updateData }) => {

    const [data, setData] = useState<IDisclosureTrackingMapping | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [invalidFields, setInvalidFields] = useState<{ name: string, message: string }[]>([]);

    // Constants
    const affiliated_business_disclosure_document_ids = data?.AffiliatedBusinessDisclosureDocumentIds;
    const charm_booklet_document_ids = data?.CHARMBookletDocumentIds;
    const special_info_booklet_document_ids = data?.SpecialInfoBookletDocumentIds;
    const heloc_brochure_document_ids = data?.HELOCBrochureDocumentIds;
    const appraisal_document_ids = data?.AppraisalDocumentIds;
    const avm_document_ids = data?.AVMDocumentIds;
    const home_counseling_disclosure_document_ids = data?.HomeCounselingDisclosureDocumentIds;
    const high_cost_disclosure_document_ids = data?.HighCostDisclosureDocumentIds;
    const provider_list_document_ids = data?.ProviderListDocumentIds;
    const safe_harbor_document_ids = data?.SafeHarborDocumentIds;
    const loan_estimate_document_ids = data?.LoanEstimateDocumentIds;
    const closing_disclosure_document_ids = data?.ClosingDisclosureDocumentIds;

    const first_borrower = "FirstBorrower";
    const all_recipients = "AllRecipients";
    const intent_to_proceed = "IntentToProceed";

    const str_affiliated_business_disclosure_document_ids = "AffiliatedBusinessDisclosureDocumentIds";
    const str_charm_booklet_document_ids = "CHARMBookletDocumentIds";
    const str_special_info_booklet_document_ids = "SpecialInfoBookletDocumentIds";
    const str_heloc_brochure_document_ids = "HELOCBrochureDocumentIds";
    const str_appraisal_document_ids = "AppraisalDocumentIds";
    const str_avm_document_ids = "AVMDocumentIds";
    const str_home_counseling_disclosure_document_ids = "HomeCounselingDisclosureDocumentIds";
    const str_high_cost_disclosure_document_ids = "HighCostDisclosureDocumentIds";
    const str_provider_list_document_ids = "ProviderListDocumentIds";
    const str_safe_harbor_document_ids = "SafeHarborDocumentIds";
    const str_loan_estimate_document_ids = "LoanEstimateDocumentIds";
    const str_closing_disclosure_document_ids = "ClosingDisclosureDocumentIds";


    // Loading Data 
    const { data: mappingData } = useConfigurationData(ConfigurationDataType.DISCLOSURE_TRACKING_MAPPING);

    const textFieldRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (mappingData) {
            setData(mappingData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mappingData]);

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (textFieldRef.current) {
                    textFieldRef.current.focus();
                    textFieldRef.current.setSelectionRange(0, textFieldRef.current.value.length);
                }
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    if (!mappingData) {
        return null;
    }

    const handleChange = (field: keyof IDisclosureTrackingMapping, value: any) => {
        if (data) {
            setData({
                ...data,
                [field]: value,
            });
        }
    };


    const handleUndo = (e: React.KeyboardEvent<HTMLInputElement>, field: keyof IDisclosureTrackingMapping) => {
        // Check for the undo ctrl Z key combo 
        if (e.ctrlKey && e.key === 'z') {
            e.preventDefault();
            if (data) {
                // Reset all data back to previous state
                setData({
                    ...data,
                    [field]: mappingData[field],
                })
            }
        }
    };

    const validateFields = () => {
        const fields = [
            { name: 'Intent to Proceed', value: data?.IntentToProceed?.DocumentIds },
            { name: 'Affiliated Business Disclosure', value: affiliated_business_disclosure_document_ids },
            { name: 'CHARM Booklet', value: charm_booklet_document_ids },
            { name: 'Special Info Booklet', value: special_info_booklet_document_ids },
            { name: 'HELOC Brochure', value: heloc_brochure_document_ids },
            { name: 'Appraisal', value: appraisal_document_ids },
            { name: 'AVM', value: avm_document_ids },
            { name: 'Home Counseling Disclosure', value: home_counseling_disclosure_document_ids },
            { name: 'High Cost Disclosure', value: high_cost_disclosure_document_ids },
            { name: 'Provider List', value: provider_list_document_ids },
            { name: 'Safe Harbor', value: safe_harbor_document_ids },
            { name: 'Loan Estimate', value: loan_estimate_document_ids },
            { name: 'Closing Disclosure', value: closing_disclosure_document_ids },
        ];

        const isValidSafeInteger = (val: number) => Number.isInteger(Number(val)) && Number(val) <= Number.MAX_SAFE_INTEGER && Number(val) >= Number.MIN_SAFE_INTEGER;
        const invalidFields = fields.filter(field => field.value && !field.value.every(isValidSafeInteger));
        if (invalidFields.length > 0) {
            setError(`Some option values are invalid:`);
            setInvalidFields(invalidFields.map(field => ({ name: field.name, message: 'Must be a comma separated list of integers' })));
            return false;
        }
        return true;

    };

    const closeDialog = () => {
        if (validateFields()) {
            updateData(data as IDisclosureTrackingMapping);
            closeFxtn();
        }
    };

    return (
        <>
            <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="lg">
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Encompass Disclosure Tracking to Docutech Document Configuration</span>
                    <IconButton
                        aria-label="close"
                        onClick={closeDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: 2,
                            height: "100%",
                        }}
                    >
                        <Box sx={{ fontWeight: "bold", fontStyle: "italic", textDecoration: "underline" }}>Disclosure Tracking Item</Box>
                        <Box sx={{ fontWeight: "bold", fontStyle: "italic", textDecoration: "underline" }}>Docutech Document Indices (comma-delimited)</Box>
                        <Box sx={{ fontWeight: "bold" }}>Intent to Proceed</Box>
                        <Box>
                            <TextField
                                inputRef={textFieldRef}
                                value={data?.IntentToProceed?.DocumentIds?.join(',') || ''} // Falling back to empty string to avoid showing 'undefined' in the text field , applied to all values below 
                                size="small"
                                onChange={(e) => handleChange(intent_to_proceed, { ...data?.IntentToProceed, DocumentIds: e.target.value.split(',') })}
                                onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, intent_to_proceed)}
                                autoFocus
                            />
                            <FormControlLabel
                                sx={{ marginLeft: "1rem" }}
                                control={
                                    <Radio
                                        onClick={() => handleChange(intent_to_proceed, { ...data?.IntentToProceed, Ingestion: first_borrower })}
                                        checked={data?.IntentToProceed?.Ingestion === first_borrower}
                                    />
                                }
                                label="1st Borrower"
                            />
                            <FormControlLabel
                                control={
                                    <Radio
                                        onClick={() => handleChange(intent_to_proceed, { ...data?.IntentToProceed, Ingestion:  all_recipients })}
                                        checked={data?.IntentToProceed?.Ingestion === all_recipients}
                                    />
                                }
                                label="All Recipients"
                            />
                        </Box>
                        <Box sx={{ fontWeight: "bold" }}>Affiliated Business Disclosure</Box>
                        <TextField

                            value={affiliated_business_disclosure_document_ids?.join(',') || ''} 
                            size="small"
                            onChange={(e) => handleChange(str_affiliated_business_disclosure_document_ids  , e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_affiliated_business_disclosure_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>CHARM Booklet</Box>
                        <TextField
                            value={charm_booklet_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_charm_booklet_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_charm_booklet_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Special Info Booklet</Box>
                        <TextField
                            value={special_info_booklet_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_special_info_booklet_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_special_info_booklet_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>HELOC Brochure</Box>
                        <TextField
                            value={heloc_brochure_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_heloc_brochure_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_heloc_brochure_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Appraisal</Box>
                        <TextField
                            value={appraisal_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_appraisal_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_appraisal_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>AVM</Box>
                        <TextField
                            value={avm_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_avm_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_avm_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Home Counseling Disclosure</Box>
                        <TextField
                            value={home_counseling_disclosure_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_home_counseling_disclosure_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_home_counseling_disclosure_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>High Cost Disclosure</Box>
                        <TextField
                            value={high_cost_disclosure_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_high_cost_disclosure_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_high_cost_disclosure_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Provider List</Box>
                        <TextField
                            value={provider_list_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_provider_list_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_provider_list_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Safe Harbor</Box>
                        <TextField
                            value={safe_harbor_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_safe_harbor_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_safe_harbor_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Loan Estimate</Box>
                        <TextField
                            value={loan_estimate_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_loan_estimate_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_loan_estimate_document_ids)}
                        />
                        <Box sx={{ fontWeight: "bold" }}>Closing Disclosure</Box>
                        <TextField
                            value={closing_disclosure_document_ids?.join(',') || ''}
                            size="small"
                            onChange={(e) => handleChange(str_closing_disclosure_document_ids, e.target.value.split(',').map(id => id.trim()))}
                            onKeyDown={(e) => handleUndo(e as React.KeyboardEvent<HTMLInputElement>, str_closing_disclosure_document_ids)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button onClick={closeDialog} variant="contained" color="primary">Close</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {error && (
                <Dialog open={true} onClose={() => setError(null)}>
                    <DialogTitle>Invalid Options</DialogTitle>
                    <DialogContent>
                        <Typography>{error}</Typography>
                        <List>
                            {invalidFields.map((field) => (
                                <ListItem key={`${field.name}-${field.message}`}>
                                    <Typography>{`• ${field.name} : ${field.message}`}</Typography>
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setError(null)}>OK</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default DisclosureTrackingMappingsDialog;