import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useActions } from "../../hooks/useActions";
import { useSelector } from "react-redux";
import { IErrorMessageOverrides } from "../../models/configuration/plugin/IConfiguration";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import { RootState, store } from "../../state/store";
import EncompassService from "../../services/encompassService";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";
import "../../assets/style.css";
import RespMessages from "../../components/configuration/messages/respMessages";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import { ConfigurationDataType, useConfigurationData } from "../../hooks/useConfigurationData";
import { ActionType } from "../../state/actions";
import { AlertSeverity } from "../../constants/AlertTypes";

const ConfigResponseMessages: React.FC = () => {

    const { saveConfigMessagesData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
   
    // Send Validations State
    const [messages, setMessages] = useState<IErrorMessageOverrides[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');
    const [saving, setSaving] = useState<boolean>(false);

    // Loading Data
    const hasFetchedData = useRef(false);
    const { data, error, loading } = useConfigurationData(ConfigurationDataType.ERROR_MESSAGE_OVERRIDES);
    const mData = useMemo(() => data, [data]);

    useEffect(() => {
        if (!hasFetchedData.current && data) {
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);          
            setMessages(mData);
            hasFetchedData.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

     // Handling Loading Error 
     useEffect(() => {
        if (!error) {
            setErrorMessage(error);
        }    
    }, [error]);

     // Handling Saving Error 
     useEffect(() => {
        if (!saveError && data !== null) {
            setMessages(mData);
        }
    }, [data, saveError, mData]);

    useEffect(() => {
        saveState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    const updateMessages = (updatedMessages: IErrorMessageOverrides[]) => {
        setMessages(updatedMessages);
    }

    // Call this to save local session redux state
    const saveState = () => {
        try {
            if (messages.length === 0) return;
            const currentState = store.getState();
            const configPlugInData = currentState.configPlugInData.data;
            const updatedConfigPlugInData = {
                ...configPlugInData,
                Configuration: {
                    ...configPlugInData?.Configuration,
                    ErrorMessageOverrides: messages
                },
            };
            store.dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS,
                payload: updatedConfigPlugInData
            });

        } catch (e) {
            console.log("Error Saving State: ", e);
            openAlert(`Error Saving State: ${e}`, 'error');
        }
    }

    const save = async () => {
        try {
            setSaving(true);
            await saveConfigMessagesData(selectedConfigId, messages, accessToken);
            openAlert("Successfully Saved Changes", "success");
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            const error = `${e}`;
            setSaveError(true);
            setErrorMessage(error);
            setSaving(false);
        } finally {
            setSaving(false);
        }
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <>
            <section>
                <div>
                    {(loading || saving) && <ConfigLoad loading={loading} />}   
                </div>
                <Box
                    mt={1} component="main" sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1, overflow: "auto", minHeight: '100vh', margin: "2px"
                    }}
                >
                    
                    <ConfigTopBar save={save} error={error || errorMessage || null} />
                    <RespMessages messages={messages}updateMessages={updateMessages} />
                    <ConfigBottomBar save={save} />                        

                </Box>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={alertOpen}
                    autoHideDuration={4000}
                    onClose={handleSnackBarClose}
                >
                    <MuiAlert
                        onClose={handleSnackBarClose}
                        severity={alertSeverity}
                        sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                        {alertMessage}
                    </MuiAlert>
                </Snackbar>
            </section>
        </>
    );
};

export default ConfigResponseMessages;