import axios from "axios";
import { IConfigAuthToken } from "../models/IConfigAuthToken";
import EncompassService from "./encompassService";
                            
const oauthClientId = process.env.REACT_APP_DT_OAUTH_CLIENT_ID as string;
const oauthClientSecret = process.env.REACT_APP_DT_OAUTH_CLIENT_SECRET as string;

/*
 * This authservice is just for development using a local proxy, this will eventually be removed as well as the constants
 */
class AuthService {

    public async getToken():  Promise<IConfigAuthToken> {
        
        const requestBody = {
            client_id: oauthClientId,
            client_secret: oauthClientSecret,
            grant_type: "client_credentials",
            scope: "plugin_api integration_write"
        };

        try {
            const url = EncompassService.getTokenEndpoint();
            let response;
                response = await axios.post(`${url}`, requestBody);
            
            return {
                token_type: response.data.token_type,
                expires_in: response.data.expires_in,
                access_token: response.data.access_token,
                error: null
            }        

        } catch(error: any) {
            console.error('Getting Token: ', error.response? error.response.data: error.message);
            throw new Error(error.response ? error.response.data : error.message);
        }
    }
}

export default AuthService;