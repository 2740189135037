import { Box, CircularProgress, Typography } from "@mui/material";
import { usePushbackLoans } from "../../hooks/pushbackDashboard/usePushbackLoans";
import { usePushbackOrderDetails } from "../../hooks/pushbackDashboard/usePushbackOrderDetails";
import { usePushbackDetail } from "../../hooks/pushbackDashboard/usePushbackDetail";
import { useState, useCallback, useRef } from "react";
import LoansList from "../../components/pushbackDashboard/loansList";
import DTDataGrid from "../../components/shared/config/DTDataGrid";
import LoansListDateFilter from "../../components/pushbackDashboard/loanListDateFilter";
import { orderDetailsColumns, pushBackDetailColumns } from "./pushbackData";
import { usePushbackClientLogs } from "../../hooks/pushbackDashboard/usePushbackClientLogs";
import { clientLogs } from "../clientData";
import PushbackReportingDialog from "./PushbackReportingDialog";
import { usePushbackReport } from "../../hooks/pushbackDashboard/usePushbackReport";
import { IPushbackEntry } from "../../models/configuration/pushback/IPushbackLoans";

const PushbackDashboard: React.FC = () => {
    
    // State
    const [selectedLoanDate, setSelectedLoanDate] = useState<string>('');
    const [selectedLoan, setSelectedLoan] = useState<string>('');
    const [orderDetailsOrderId, setOrderDetailsOrderId] = useState<string>('');
    const [reportingDialogOpen, setReportingDialogOpen] = useState(false);
    const [reportOrderId, setReportOrderId] = useState<string>('');
    const [reportEntryId, setReportEntryId] = useState<string>('');
    const [topHeight, setTopHeight] = useState(300); // Initial height Order Details grid
    const containerRef = useRef<HTMLDivElement>(null);

    // Hooks
    const { data: loansData, error: loansError, loading: loansIsLoading } = usePushbackLoans(selectedLoanDate);
    const { data: orderDetailsData, error: orderDetailsError, loading: orderDetailsIsLoading } = usePushbackOrderDetails(selectedLoan);
    const { data: detailData, error: detailError, loading: detailIsLoading } = usePushbackDetail(orderDetailsOrderId);
    const { data: logData, error: logError, loading: logIsLoading } = usePushbackClientLogs(selectedLoan);
    const { data: reportData } = usePushbackReport(reportOrderId, reportEntryId);

    // Handlers
    const handleLoanChange = useCallback((loanNumber: string) => {
        setSelectedLoan(loanNumber);
        setOrderDetailsOrderId('');
    }, []);

    const handleLoansDateChange = useCallback((date: string) => {
        setSelectedLoanDate(date);
        setSelectedLoan('');
        setOrderDetailsOrderId('');
    }, []);

    const handleViewItem = useCallback((e: any, item: any) => {
        setOrderDetailsOrderId(item.OrderId);
    }, []);

    const ViewReport = useCallback((e: any, item: IPushbackEntry) => {
        setReportOrderId(item.OrderId);
        setReportEntryId(item.EntryId);
        setReportingDialogOpen(true);
    }, []);

    const reportingDialogClose = useCallback(() => {
        setReportingDialogOpen(false);
    }, []);

    /*
     * Splitter handler
     * This handle is used to manually enabled dragging functionality for the splitter line on the dashboard 
     * between the two grid on the right panel.
     * This code can eventually be replaced with react-split-pane 
     * but since that requires a react upgrade I put in a manual solution for the time being 
     */
    const handleMouseDown = (event: React.MouseEvent) => {
        // Captures the initial vertical position of the mouse one the mouse is clicked against the splitter line  
        const startY = event.clientY;

        // This handless the moving up or down - basically within a calculated window 
        const handleMouseMove = (e: MouseEvent) => {
            // only execute if the container reference is not null since with need that ref for calculations
            if (containerRef.current) {
                // gets the current position of the container relative to the viewport
                const containerRect = containerRef.current.getBoundingClientRect();
                // can move up to height of top section with 100 and height - x pixels (Currently)
                const newTopHeight = Math.max(100, Math.min(containerRect.height - 100, topHeight + (e.clientY - startY)));
                // resets the new top height each time 
                setTopHeight(newTopHeight);
            }
        };

        // this just releases the dragging operation on mouse up
        const handleMouseUp = () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };

        // adds back in the dragging operation 
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
    }

    return (
        <>
            <Typography variant="h4" sx={{ marginLeft: '5px', marginBottom: '10px' }}>Pushback Dashboard</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    boxSizing: 'border-box',
                    overflow: 'hidden'
                }}
            >
                {/* Top Section  */}
                <Box sx={{ display: 'flex', flex: 1, margin: '10px 5px, 10px, 5px' }}>

                    {/* Left Panel */}
                    <Box sx={{ flex: '0 0 10%' }}>
                        <LoansListDateFilter
                            DefaultDate={new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString('en-US')}
                            OutputDate={handleLoansDateChange}
                        />
                        {loansIsLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress />
                            </Box>
                        ) : loansError ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Typography color="error">Error loading loans: {loansError}</Typography>
                            </Box>
                        ) : (
                            <LoansList
                                loans={loansData?.LoanNumbers || []}
                                selectedLoan={selectedLoan}
                                selectLoan={handleLoanChange}
                            />
                        )}
                    </Box>

                    {/* Right Panel */}
                    <Box
                        ref={containerRef}
                        sx={{ height: '600px', display: 'flex', flexDirection: 'column', width: '100%', marginLeft: '10px' }}
                    >
                        {/* Order Details Section */}
                        <Box
                            sx={{
                                height: `${topHeight}px`,
                                overflow: 'auto',
                                borderBottom: '2px solid #ccc',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {orderDetailsIsLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <CircularProgress />
                                </Box>
                            ) : orderDetailsError ? (
                                <Typography color="error">Error loading order details: {orderDetailsError}</Typography>
                            ) : (
                                <DTDataGrid
                                    data={orderDetailsData?.OrderDetails || []}
                                    columns={orderDetailsColumns(handleViewItem)}
                                    tableName="orderDetails"
                                    containerSx={{ height: '100%', overflow: 'auto' }}
                                />
                            )}
                        </Box>

                        {/* Splitter Line */}
                        <Box
                            onMouseDown={handleMouseDown}
                            sx={{
                                height: '3px',
                                backgroundColor: '#ccc',
                                cursor: 'row-resize',
                            }}
                        />

                        {/* Pushback Details Section */}
                        <Box
                            sx={{
                                flex: 1,
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {detailIsLoading ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <CircularProgress />
                                </Box>
                            ) : detailError ? (
                                <Typography color="error">Error loading pushback details: {detailError}</Typography>
                            ) : (
                                <DTDataGrid
                                    data={detailData?.PushbackEntries || []}
                                    columns={pushBackDetailColumns(ViewReport)}
                                    tableName="pushBackDetails"
                                    containerSx={{ height: '100%', overflow: 'auto' }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>

                {/* Logs Section */}
                <Box sx={{ flex: 1, margin: '15px 5px 10px 10px' }}>
                    {logIsLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : logError ? (
                        <Typography color="error">Error loading logs: {logError}</Typography>
                    ) : (
                        <DTDataGrid
                            data={logData?.ClientLogEntries || []}
                            columns={clientLogs()}
                            tableName="logs"
                            containerSx={{ height: '300px', overflow: 'auto' }}
                        />
                    )}
                </Box>
                <PushbackReportingDialog
                    open={reportingDialogOpen}
                    closeFxtn={reportingDialogClose}
                    reportData={reportData}
                />
            </Box>
        </>
    );
};

export default PushbackDashboard;