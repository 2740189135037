import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import { Action, ActionType } from "../actions";

interface ConfigPluginDataState {
    loading: boolean;
    error: string | null;
    data: IPluginConfiguration | null
}

const initialState = { loading: false, error: null, data: null }

const configPlugInReducer = (
    state: ConfigPluginDataState = initialState,
    action: Action
): ConfigPluginDataState => {

    switch (action.type) {
        // GET DATA
        case ActionType.GET_CONFIG_PLUGIN_DATA:  // This is the process of getting the data
            return { ...state, loading: true, error: null };
        case ActionType.GET_CONFIG_PLUGIN_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload};
        case ActionType.GET_CONFIG_PLUGIN_DATA_SUCCESS:
            return { ...state, loading: false, error: null, data: action.payload }

        // SAVE DATA    
        case ActionType.SAVE_CONFIG_PLUGIN_DATA:
            return { ...state, loading: true, error: null, data: state.data };
        case ActionType.SAVE_CONFIG_PLUGIN_DATA_FAILURE:
            return { ...state,  loading: false, error: action.payload, data: state.data }
        case ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS:
            return { ...state, loading: false, error: null, data: action.payload }
        default:
            return state;
    }
};

export default configPlugInReducer;