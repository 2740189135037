import React, { useState, useEffect, useRef, useCallback } from "react";
import MuiAlert from '@mui/material/Alert';
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import DTDataGrid from "../../../components/shared/config/DTDataGrid";
import { IEventBundle } from "../../../models/configuration/bundles/IBundles";
import { Box, Button, Snackbar, TextField, Typography } from "@mui/material";
import ConfigLoad from "../../../components/shared/config/ConfigLoad";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import EncompassService from "../../../services/encompassService";
import { otherDocColumns } from "./configbundlingdata";
import OtherDocumentBundlingDialog from "./OtherDocumentBundlingDialog";
import ConfirmationDialog from "../../../components/shared/ConfirmDialog";
import "../../../assets/style.css";
import { AlertSeverity } from "../../../constants/AlertTypes";

interface OtherDocumentBundlingProps {
  updateOtherDocuments: (eventBundles: Array<IEventBundle>) => void;
}

const OtherDocumentBundling: React.FC<OtherDocumentBundlingProps> = ({ updateOtherDocuments }) => {

  // Reducers
  const { getConfigBundleEventData } = useActions();
  const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
  const { data, error, loading } = useTypedSelector((state) => state.configEventBundles);

  // State
  const [otherDocEventBundles, setOtherDocEventBundles] = useState<Array<IEventBundle>>([]);
  const [selectedEventBundle, setSelectedEventBundle] = useState<IEventBundle | null>(null);
  const [editBundlingDialog, setEditBundlingDialog] = useState<boolean>(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [newEvent, setNewEvent] = useState<string>('');

  // State Confirmations
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<AlertSeverity>('info');

  // Refs
  const hasFetched = useRef(false);
  const hasFetchedConfigIds = useRef(false);

  const fetchBundles = useCallback((configId: string) => {
    getConfigBundleEventData(configId, accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getConfigBundleEventData]);


  useEffect(() => {
    if (!hasFetched.current) {
      const configId = EncompassService.getConfigId() as string;
      const delayFetch = setTimeout(() => {
        fetchBundles(configId);
        hasFetchedConfigIds.current = true;
      }, 1000);

      return () => clearTimeout(delayFetch); // Cleanup timeout
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data != null) {
      setOtherDocEventBundles(data.Configuration.Bundling);
    }
  }, [data]); // Run once the component mounts

  function editItem(e: any, item: any) {
    if (otherDocEventBundles && otherDocEventBundles.length > 0 && item.EventName) {
      const bundle = otherDocEventBundles.filter((bundle) => bundle.EventName === item.EventName);
      if (bundle && bundle[0].Configuration && bundle[0].EventName === item.EventName) {
        setSelectedEventBundle(bundle[0]);
        setEditBundlingDialog(true);
      }
    }
  }

  const deleteItem = (e: any, item: any) => {
    setSelectedEventBundle(item);
    setConfirmDialogOpen(true);
  };

  function deleteBundle(item: IEventBundle | null) {
    if (item) {
      // find the newOtherDocEventBundles without the one deleted
      const newOtherDocEventBundles = otherDocEventBundles.filter((bundle) => bundle !== item);
      setOtherDocEventBundles(newOtherDocEventBundles);
      setSelectedEventBundle(null);
      setConfirmDialogOpen(false);
      openAlert(`Bundle Configuration for ${item.EventName} Deleted Successfully`, 'success');
    }
    setConfirmDialogOpen(false);
  }

  const updateEventBundle = (eventBundle: IEventBundle) => {
    setOtherDocEventBundles((prevBundles) =>
      prevBundles.map((bundle) =>
        bundle.EventName === eventBundle.EventName
          ? { ...bundle, Configuration: eventBundle.Configuration }
          : bundle
      )
    );
    updateOtherDocuments(otherDocEventBundles);
  };

  function cancelDeleteBundle() {
    setConfirmDialogOpen(false);
    setSelectedEventBundle(null);
  }

  // Alert Handling 
  const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
  const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  }

  const closeFxtn = () => {
    setEditBundlingDialog(false);
  };

  const addNewEvent = (newEventName: string) => {
    // use the latest bundle to create a new bundle
    const latestBundle = otherDocEventBundles[otherDocEventBundles.length - 1];
    if (latestBundle && latestBundle.Configuration) {
      const newBundle = { EventName: newEventName, Configuration: [...latestBundle.Configuration] };
      // Updated main state
      setOtherDocEventBundles([...otherDocEventBundles, newBundle]);
      // updated parent state
      updateOtherDocuments([...otherDocEventBundles, newBundle]);
      openAlert(`New bundle for ${newEventName} added successfully`, 'success');
      setNewEvent('');
    }
  }

  return (
    <>
      <div>
        <ConfigLoad loading={loading} />
      </div>
      {error ? (
        <Typography
          sx={{
            padding: 1,
            border: "1px solid black",
            borderRadius: "2px",
          }}
          variant="body1"
          color="error"
        >
          Unable to load data: {error}
        </Typography>
      ) : (
        <>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', margin: '1rem' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%', marginBottom: '10px' }}>
              <Typography variant="subtitle1" gutterBottom>
                Event Type
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%', marginBottom: '10px' }}>
              <TextField
                id="outlined-basic"
                label="add event name"
                variant="outlined"
                size="small"
                value={newEvent}
                onChange={(e) => setNewEvent(e.target.value)}
                sx={{ width: '300px' }} />
              <Button
                onClick={() => addNewEvent(newEvent)}
                variant="contained"
                color="primary"
                disabled={newEvent && newEvent.length >= 6 ? false : true}
                sx={{ marginLeft: '10px' }}>
                Add
              </Button>
            </Box>
          </Box>

          <DTDataGrid
            data={otherDocEventBundles}
            columns={otherDocColumns(editItem, deleteItem)}
            tableName="otherdocs"
          />

          <OtherDocumentBundlingDialog
            eventBundle={selectedEventBundle}
            open={editBundlingDialog}
            closeFxtn={closeFxtn}
            updateEventBundle={updateEventBundle}
          />

          <ConfirmationDialog
            open={confirmDialogOpen}
            cancelName="No"
            confirmName="Yes"
            title="Delete Configuration"
            message={`Are you sure you want to delete the Configuration for '${selectedEventBundle?.EventName}'?`}
            onConfirm={() => deleteBundle(selectedEventBundle)}
            onCancel={() => cancelDeleteBundle()}
          />

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertOpen}
            autoHideDuration={4000}
            onClose={handleSnackBarClose}
          >
            <MuiAlert
              onClose={handleSnackBarClose}
              severity={alertSeverity}
              sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </>

      )
      }
    </>
  );
};

export default OtherDocumentBundling;