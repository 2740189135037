import { Button, Card, CardContent, Grid, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import DtTab from "./DtTab";
import LogTabs from "./LogTabs"; 
import { IRequestId } from "../../models/IRequestIds";
import PreviewDocsService from "../../services/previewDocsService";
import { useDispatch } from "react-redux";

interface OrderTabsProps {
    logsTabVisible: boolean,
    logsTabEnabled: boolean,
    logsTabTitle: string,
    requestResponseTabVisible: boolean,
    requestResponseTabEnabled: boolean,
    requestResponseTabTitle: string,
    requests: Array<IRequestId>,
    authToken: string
}


const OrderTabs: React.FC<OrderTabsProps> = (
    {
        logsTabVisible,
        logsTabTitle,
        logsTabEnabled,
        requestResponseTabVisible,
        requestResponseTabTitle,
        requestResponseTabEnabled,
        requests,
        authToken
    }
) => { 
    const previewDocsService = new PreviewDocsService();   
    const [tabValue, setTabValue] = useState(1);

    useEffect(() => {
        setTabValue(requestResponseTabEnabled ? 0 : 1);
    }, [requestResponseTabEnabled]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if ((newValue === 0 && requestResponseTabEnabled) || (newValue === 1 && logsTabEnabled)) {
            setTabValue(newValue);
        }
    }

    const handleDownloadMetaData = async (transactionId: string | null) => {
        await previewDocsService.downloadMetadata(transactionId, authToken, requests);
    }

    const handleDownloadFiles = async (transactionId: string | null) => {
       await previewDocsService.previewDocument(transactionId,authToken,requests)
    }

    const isTabAreaVisible = requestResponseTabVisible || logsTabVisible;

    return (
        <Card sx={{ display: true ? "block" : "none" }}>
            <CardContent sx={{ padding: 0 }}>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    {requestResponseTabVisible && (
                        <Tab label={requestResponseTabTitle} disabled={!requestResponseTabEnabled} />
                    )}
                    {logsTabVisible && (
                        <Tab label={logsTabTitle} disabled={!logsTabEnabled} />
                    )}
                </Tabs>
                <DtTab value={tabValue} index={0}>
                    {requestResponseTabVisible && (
                        <>
                            {requests[0]?.RequestId &&
                                <Grid container justifyContent='flex-start'>
                                    Request Id: {requests[0]?.RequestId}
                                </Grid>
                            }
                            <Grid container justifyContent='flex-end'>
                                <Button disabled={requests.length <= 0}
                                    sx={{ 'marginRight': 1 }}
                                    variant='outlined'
                                    size='small'
                                    color='info'
                                    onClick={async () => await handleDownloadMetaData(null)}>
                                    Download Request and Response
                                </Button>
                                <Button disabled={requests.length <= 0}
                                    variant="outlined"
                                    size="small"
                                    color="info"
                                    onClick={async () => await handleDownloadFiles(null)}>
                                    Preview Documents
                                </Button>
                            </Grid>
                        </>
                    )}
                </DtTab>
                <DtTab value={tabValue} index={1}>
                    {logsTabVisible && <LogTabs />}
                </DtTab>
            </CardContent>
        </Card>
    )
}
export default OrderTabs; 