import dayjs from "dayjs";
import { IClientLog } from "../models/configuration/pushback/IPushbackReport";
import { DTDataGridColumnType, IDataColumn } from "../models/IDataColumn";

export const clientLogs = (
): Array<IDataColumn> => {
    return [
        {
            name: "LogTime",
            fieldId: "LoggedTimestamp",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            dataFunction: (item: IClientLog) => {
                return dayjs(item.LoggedTimestamp).format('MM/DD/YYYY hh:mm:ss A Z');
            }
        },
        {
            name: "Severity",
            fieldId: "SeverityLevel",
            datatype: DTDataGridColumnType.text,
            sortable: true,
            foreColorFunction: (item: IClientLog) => {
                switch (item.SeverityLevel) {
                    case "Warning":
                        return "orange";
                    case "Complete":
                        return "green";
                    case "Alert":
                        return "red";
                    default:
                        return "black";
                }
            }
        },
        {
            name: "User",
            fieldId: "EncompassUser",
            datatype: DTDataGridColumnType.text,
            sortable: true
        },
        {
            name: "Message",
            fieldId: "Message",
            datatype: DTDataGridColumnType.text,
            sortable: true,
        }
    ];
};