import { IXpathFieldMap } from "../../models/configuration/xpathFieldMap/IXpathFieldMap";

import { Action, ActionType } from "../actions";

interface XpathFieldMapState {
    loading: boolean;
    error: string | null;
    data: IXpathFieldMap | null;
    };

const initialState = { loading: false, error: null, data: null };

const configXPathFieldMapReducer = (
    state: XpathFieldMapState = initialState,
    action: Action
): XpathFieldMapState => {
    switch (action.type) {
        // GET DATA
        case ActionType.GET_CONFIG_XPATH_FIELD_MAPPING:
            return { loading: true, error: null, data: null };
        case ActionType.GET_CONFIG_XPATH_FIELD_MAPPING_FAILURE:
            return { loading: false, error: action.payload, data: null };
        case ActionType.GET_CONFIG_XPATH_FIELD_MAPPING_SUCCESS:
            return { loading: false, error: null, data: action.payload };        
        default:
            return state;
    }
};

export default configXPathFieldMapReducer;