import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import IIntegration from '../../models/configuration/servers/IIntegration';
import { RootState } from '../store';
import PluginService from '../../services/pluginService';
import ISaveIntegration from '../../models/configuration/servers/ISaveIntegration';

interface IntegrationState {
  integration: IIntegration | null;
  loading: boolean;
  error: string | null;
}

const initialState: IntegrationState = {
  integration: null,
  loading: false,
  error: null,
};

export const importIntegration = createAsyncThunk(
  'integration/importIntegration',
  async ({ configId, integration }: { configId: string, integration: IIntegration }, { getState }) => {
    const state = getState() as RootState;
    const token = state.appSlice.accessToken;
    const pluginService = new PluginService();
    const requestBody = {
      "ConfigurationId": configId,
      "Configuration": {
        ...integration
      }
    }
    return await pluginService.saveConfig<ISaveIntegration>(token, "integration", configId, requestBody);
  }
);

const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    setIntegration(state, action: PayloadAction<IIntegration>) {
      state.integration = action.payload;
    },
    clearIntegration(state) {
      state.integration = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(importIntegration.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(importIntegration.fulfilled, (state, action: PayloadAction<ISaveIntegration>) => {
        state.loading = false;
        state.integration = action.payload.Configuration;
      })
      .addCase(importIntegration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to save integration';
      });
  },
});

export const { setIntegration, clearIntegration } = integrationSlice.actions;
export default integrationSlice.reducer;
