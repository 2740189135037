import { IDocGenStatusModel } from "../models/IDocGenStatusModel";
import { IPresignUrlModel } from "../models/IPresignUrlModel";
import ApiService from "./apiService";



class DocGenApiService {
    private apiService!: ApiService

    constructor() {
        this.apiService = new ApiService(process.env.REACT_APP_INTEGRATION_API ?? "");
    }

    public async getPresignResponse(transactionId: string, token: string, objectKeys: Array<string>) {
        const response = await this.apiService.get<IPresignUrlModel>(`/presign-url`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
            params: {
                RequestId: transactionId,
                ObjectKeysCsv: objectKeys.join(',')
            }
        });
        return response.data;
    }

    public async getDocGenStatus(transactionId: string, token: string) {
        const response = await this.apiService.get<IDocGenStatusModel>(`/docgen/${transactionId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        return response.data;
    }
}

export default DocGenApiService;