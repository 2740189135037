import { useEffect, useCallback } from "react";
import { useActions } from "../useActions";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import EncompassService from "../../services/encompassService";
import { useTypedSelector } from "../useTypedSelector";

function usePushbackReport(orderId: string, entryId: string) {
 
    const { getPushbackReportData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.pushbackReport);

    const isOrderIdValid = orderId && orderId.trim() !== "";
    const isEntryIdValid = entryId && entryId.trim() !== "";

    // Memoized fetch function
    const fetchData = useCallback(async () => {
        if (isOrderIdValid && isEntryIdValid) {
            const configId = EncompassService.getConfigId();
            if (configId) {
                await getPushbackReportData(configId, accessToken, orderId, entryId); // Access token not required since proxy will generate if needed locally
            }
        }
    }, [getPushbackReportData, accessToken, orderId, entryId, isOrderIdValid, isEntryIdValid]);

    useEffect(() => {
        if (isOrderIdValid && isEntryIdValid) {
            fetchData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId, entryId]);

    // Return error if loanNumber is invalid
    if (!isOrderIdValid || !isEntryIdValid) {
        return { data: null, loading: false };
    }

    return { data, error, loading };
}

export { usePushbackReport };