import React, { useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, Button, Typography, Tab, Tabs, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IPushbackReport } from '../../models/configuration/pushback/IPushbackReport';
import dayjs from 'dayjs';
import { pushBackDetailColumnReport } from './pushbackData';
import DTDataGrid from '../../components/shared/config/DTDataGrid';
import { clientLogs } from '../clientData';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface PushbackReportDialogProps {
    open: boolean;
    closeFxtn: () => void;
    reportData: IPushbackReport | null;
}

const PushbackReportingDialog: React.FC<PushbackReportDialogProps> = ({ open, closeFxtn, reportData }) => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const closeDialog = () => {
        closeFxtn();
    };

    return (
        <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="lg">
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Pushback Report [{reportData?.Report?.PushbackEntry.OrderId}]</span>
                <IconButton
                    aria-label="close"
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        height: "100%",
                    }}
                >
                    {/* Push Back Details */}
                    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
                        <Typography>Pushback Details:</Typography>
                        <Box sx={{ display: 'grid', gridTemplateColumns: '200px 1fr', gap: 1, margin: "1px 0 0 5px" }}>
                            <Typography variant="body1"><strong>Loan Number:</strong></Typography>
                            <Typography variant="body1">{reportData?.Report?.PushbackEntry.LoanNumber}</Typography>
                            <Typography variant="body1"><strong>Reason:</strong></Typography>
                            <Typography variant="body1">{reportData?.Report?.PushbackEntry.Reason}</Typography>
                            <Typography variant="body1"><strong>Package Type:</strong></Typography>
                            <Typography variant="body1">{reportData?.Report?.PushbackEntry.PackageType}</Typography>
                            <Typography variant="body1"><strong>Pushback Received:</strong></Typography>
                            <Typography variant="body1">{dayjs(reportData?.Report?.PushbackEntry.ReceivedTimestamp).format('MM/DD/YYYY hh:mm:ss A Z')}</Typography>
                            <Typography variant="body1"><strong>Status:</strong></Typography>
                            <Typography variant="body1">{reportData?.Report?.PushbackEntry.Status}</Typography>
                            <Typography variant="body1"><strong>Last Status Change:</strong></Typography>
                            <Typography variant="body1">{dayjs(reportData?.Report?.PushbackEntry.LastStatusChangeTimestamp).format('MM/DD/YYYY hh:mm:ss A Z')}</Typography>
                        </Box>
                    </Box>

                    {/* Tabs */}
                    <Box sx={{ width: '100%' }}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Process History" {...docPanelProps(0)} />
                            <Tab label="Client Logs" {...docPanelProps(1)} />
                            <Tab 
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        PushBack
                                        <Tooltip title="Copy to clipboard">
                                            <IconButton 
                                                onClick={(e) => { 
                                                    e.stopPropagation();
                                                    navigator.clipboard.writeText(reportData?.Report?.PushbackEventXml || ''); 
                                                }}
                                                size="small"
                                                sx={{ ml: 1 }}
                                            >
                                                <ContentCopyIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                } 
                                {...docPanelProps(2)}
                            />
                        </Tabs>
                        <DocsTabPanel value={tabValue} index={0}>
                            <Box sx={{ width: '100%', height: 300 }}>
                                <DTDataGrid
                                    data={reportData?.Report.PushbackHistoryEntries || []}
                                    columns={pushBackDetailColumnReport()}
                                    tableName="pushBackDetails"
                                    containerSx={{ height: '100%', width: '100%', overflow: 'auto' }}
                                />
                            </Box>
                        </DocsTabPanel>
                        <DocsTabPanel value={tabValue} index={1}>
                            <Box sx={{ width: '100%', height: 300 }}>
                                <DTDataGrid
                                    data={reportData?.Report.ClientLogEntries || []}
                                    columns={clientLogs()}
                                    tableName="logs"
                                    containerSx={{ height: '100%', width: '100%', overflow: 'auto' }}
                                />
                            </Box>
                        </DocsTabPanel>
                        <DocsTabPanel value={tabValue} index={2}>
                            <Box
                                sx={{
                                    width: "100%",
                                    height: 300,
                                    border: '1px solid black',
                                    overflowY: 'auto', 
                                    overflowX: 'hidden', 
                                    whiteSpace: 'pre-wrap', 
                                    wordWrap: 'break-word', 
                                    wordBreak: 'break-word', 
                                    padding: 1
                                }}
                            >
                                <pre>{reportData?.Report?.PushbackEventXml}</pre>
                            </Box>
                        </DocsTabPanel>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={closeDialog} variant="contained" color="primary">Close</Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function DocsTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`bundle-tabpanel-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1, height: 1 }}>{children}</Box>}
        </div>
    );
}

function docPanelProps(index: number) {
    return {
        id: `bundle-tab-${index}`,
    };
}

export default PushbackReportingDialog;