import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IPushbackConfiguration } from '../../models/configuration/importExport/IPushbackConfiguration';
import PluginService from '../../services/pluginService';
import { RootState } from '../store'; // Adjust the path as necessary
import EncompassService from '../../services/encompassService';

interface ImportPushbackState {
  config: IPushbackConfiguration | null;
  loading: boolean;
  error: string | null;
}

const initialState: ImportPushbackState = {
  config: null,
  loading: false,
  error: null,
};

export const saveImportPushbackConfig = createAsyncThunk(
  'importPushback/saveImportPushbackConfig',
  async ({ configurationId, config }: { configurationId: string; config: IPushbackConfiguration }, { getState }) => {
    const state = getState() as RootState;
    const token = state.appSlice.accessToken;
    const requestBody = {
        "ConfigurationId": configurationId,
        "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
        "Configuration": {
            ...config
        }
    }
    return await new PluginService().saveConfig(token, "pushback-processor", configurationId, requestBody);
  }
);

const importPushbackSlice = createSlice({
  name: 'importPushback',
  initialState,
  reducers: {
    setImportPushbackConfig(state, action: PayloadAction<IPushbackConfiguration>) {
      state.config = action.payload;
    },
    clearImportPushbackConfig(state) {
      state.config = null;
    },
  },
  extraReducers: (builder) => {
    builder      
      .addCase(saveImportPushbackConfig.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveImportPushbackConfig.fulfilled, (state, action: PayloadAction<{ ConfigurationId: string; EncompassInstanceId: string; Configuration: IPushbackConfiguration }>) => {
        state.loading = false;
        state.config = action.payload.Configuration; 
      })
      .addCase(saveImportPushbackConfig.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to save import pushback config';
      });
  },
});

export const { setImportPushbackConfig, clearImportPushbackConfig } = importPushbackSlice.actions;
export default importPushbackSlice.reducer;
